import React from 'react';
import { UserOrder } from '../../type/UserOrder';
import { UserOrderItem } from '../../type/UserOrderItem';
import { CellProperty, HeaderDefinition, TableHeaderCell } from '../utils/bigTable/definition/HeaderDefinition';
import { DataSource } from '../utils/bigTable/definition/DataSource';
import ConstantValuesBigTableFactory from '../utils/bigTable/ConstantValuesBigTableFactory';
import { useTranslation } from 'react-i18next';
import AseclaButton from '../utils/AseclaButton';
import PropertiesDisplay, { PropertyDisplayElement } from './PropertiesDisplay';
import { ContextProps } from '../../type/ContextProps';
import AseclaDataContext from '../../store/AseclaDataContext';
import { BONUS_CODE, DATE_OF_PAYMENT, DOWNLOAD_INVOICE, INVOICING_AND_PAYMENT, LICENSE_NAME, LICENSE_TECHNICAL_NAME, ORDER_ID, ORDER_PRICE, ORDER_TIME, ORGANIZATION, ORGANIZATION_DISCOUNT, PRODUCT_NAME, PRODUCT_TECHNICAL_NAME, RowData, STATUS, TOTAL, TOTAL_DISCOUNT, UPLOAD_DOCUMENT, USER_ID, UserOrderTableColumns } from './UserOrderTableColumns';
import TitleBanner from '../utils/TitleBanner';
import OrderHistory from './details/OrderHistory';
import HeaderWithHint from '../utils/HeaderWithHint';
import { useNavigate } from 'react-router-dom';


const predefineHeaders = (aseclaAdmin: boolean): HeaderDefinition[] => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    let headers: HeaderDefinition[] = [];

    const defineTableHeader = (props: HeaderDefinition) => {
        headers.push(props);
    }

    const get = (getValue: (item: UserOrderItem) => any): (cellProperty: CellProperty, dataSource: DataSource) => any => {
        return (cellProperty: CellProperty, dataSource: DataSource) => getValue(cellProperty.object);
    }

    let defineHeaderCell = (key: string) : TableHeaderCell => {
        return {
            getString: () => t(key),
            getElement: () => <HeaderWithHint headerKey={key}/>
        }
    }
    let getBonusCodeDiscount = (cellProperty: CellProperty, dataSource: DataSource) => {
        let item: UserOrderItem = cellProperty.object;
        if (item.bonusCodeDiscountPercentage) {
            return item.bonusCodeDiscountPercentage + "%";
        }
        if (item.bonusCodeDiscountValue) {
            return item.bonusCodeDiscountValue + "zł";
        }
        return "-";
    }
    let getFinalItemDiscount = (cellProperty: CellProperty, dataSource: DataSource) => {
        let item: UserOrderItem = cellProperty.object;
        let order: UserOrder = (dataSource.other as any)["userOrder"];
        let price = item.price;

        let percentageDiscount: number = 0;
        if (order.organizationDiscount) {
            percentageDiscount = order.organizationDiscount;
        }
        if (item.bonusCodeDiscountPercentage && item.bonusCodeDiscountPercentage > percentageDiscount) {
            percentageDiscount = item.bonusCodeDiscountPercentage;
        }

        let discountInCurrencyFromPerc = 0;
        if (percentageDiscount) {
            discountInCurrencyFromPerc = price * percentageDiscount / 100;
        }
        if (item.bonusCodeDiscountValue && item.bonusCodeDiscountValue > discountInCurrencyFromPerc) {
            return item.bonusCodeDiscountValue + "zł";
        }
        if (percentageDiscount) {
            return percentageDiscount + "%";
        }
        return "-";
    }
    
    let getLicenseCode = (cellProperty: CellProperty, dataSource: DataSource) => {
        let item: UserOrderItem = cellProperty.object;
        if (item.licenseCode == null) {
            return "";
        }
        return <AseclaButton action={e => {navigate("/aseclaAdminLicenseCodes?search=" + item.licenseCode.id)}}>{item.licenseCode.id}</AseclaButton>
    }
    
    defineTableHeader({headerKey: "LICENSE_CODE", display: aseclaAdmin, alwaysHide:!aseclaAdmin, displayedHeader: t("License Code"), getCellContent: getLicenseCode});
    defineTableHeader({headerKey: "PRODUCT_NAME", display: true, displayedHeader: t("Product"), getCellContent: get((item) => item.licenseType.product?.names["en"])});
    defineTableHeader({headerKey: "LICENSE_NAME", display: true, displayedHeader: t("License"), getCellContent: get((item) => item.licenseType.names["en"])});
    defineTableHeader({headerKey: "LENGTH", display: true, displayedHeader: defineHeaderCell("LicenseLength"), getCellContent: get((item) => item.length + " " + t(item.lengthUnit))});
    defineTableHeader({headerKey: "QUANTITY", display: true, displayedHeader: t("Quantity"), getCellContent: get((item) => item.noOfUsers)});
    defineTableHeader({headerKey: "PRICE", display: true, displayedHeader: t("Net price per License"), getCellContent: get((item) => (item.price/item.noOfUsers) + "zł")});
    defineTableHeader({headerKey: "SUBTOTAL", display: true, displayedHeader: t("Subtotal") + " " + t("net"), getCellContent: get((item) => item.price + "zł")});
    defineTableHeader({headerKey: "BONUSCODE_DISCOUNT", display: false, alwaysHide:!aseclaAdmin, displayedHeader: t("Bonuscode discount"), getCellContent: getBonusCodeDiscount});
    defineTableHeader({headerKey: "ITEM_DISCOUNT", display: true, displayedHeader: defineHeaderCell("Item Discount"), getCellContent: getFinalItemDiscount});
    
    return headers;
}

type OrderProperties = {
    aseclaAdminModule: boolean,
    rowData: RowData,
    modifyRowData: (rowData: RowData) => void,
    userOrder: UserOrder,
    orderProperties: HeaderDefinition[]
}
function OrderProperties({aseclaAdminModule, rowData, modifyRowData, userOrder, orderProperties}: OrderProperties) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    let propertiesHeaderDefinition = (key: String): HeaderDefinition|undefined => {
        for (let i = 0; i < orderProperties.length; i++) {
            if (orderProperties[i].headerKey === key) {
                return orderProperties[i];
            }
        }
        return undefined;
    }

    let getHeader = (header: string | TableHeaderCell): JSX.Element => {
        if (typeof header === "string") {
            return <>{header as string}</>;
        } else {
            return (header as TableHeaderCell).getElement();
        }
    }

    let propertiesToDisplay: PropertyDisplayElement[] = [];
    const addProperty = (key: String, adminSpecific: boolean = false) => {
        let prop: HeaderDefinition|undefined = propertiesHeaderDefinition(key);
        if (prop !== undefined && !prop.alwaysHide) {
            let datasource: DataSource = {
                rowData: [rowData],
                setRowData(rowNo, row) {
                    modifyRowData(row);
                }
            };
            let cellContent: any = prop.getCellContent({
                object: userOrder,
                rowNo: 0,
            }, datasource);
            propertiesToDisplay.push({displaydHeader: getHeader(prop.displayedHeader), value: cellContent, className: adminSpecific ? "asecla-admin" : ""});
        }
    }
    addProperty(ORDER_ID);
    addProperty(ORDER_TIME);
    addProperty(USER_ID);
    addProperty(TOTAL);
    addProperty(STATUS);
    addProperty(ORGANIZATION, true);
    addProperty(ORGANIZATION_DISCOUNT, true);
    if (aseclaAdminModule || props.isManager()) {
        addProperty(TOTAL_DISCOUNT);
    }
    addProperty(LICENSE_NAME);
    addProperty(LICENSE_TECHNICAL_NAME, true);
    addProperty(PRODUCT_NAME);
    addProperty(PRODUCT_TECHNICAL_NAME, true);
    addProperty(ORDER_PRICE);
    addProperty(BONUS_CODE);
    addProperty(DOWNLOAD_INVOICE);
    
    addProperty(UPLOAD_DOCUMENT, true);
    addProperty(DATE_OF_PAYMENT);
    addProperty(INVOICING_AND_PAYMENT, true);

    return <PropertiesDisplay properties={propertiesToDisplay}></PropertiesDisplay>;
}



type OrderDetailsParams = {
    userOrder: UserOrder,
    rowData: RowData,
    modifyRowData: (rowData: RowData) => void,
    aseclaAdminModule: boolean,
    tableHeaders: HeaderDefinition[],
    changeOrderToShow: (orderNo: number, newOrderToShow: UserOrder | null) => void,
    setOrderToConfirmInvoiceData:  React.Dispatch<React.SetStateAction<UserOrder | null>>,
}
function OrderDetails({userOrder, rowData, modifyRowData, aseclaAdminModule, tableHeaders, changeOrderToShow, setOrderToConfirmInvoiceData}: OrderDetailsParams) {
    const {t} = useTranslation();
    const [allHeaders, ] = React.useState<HeaderDefinition[]>(predefineHeaders(aseclaAdminModule));
    
    let otherHeaderButtons = [
        <AseclaButton className="small" action={e => changeOrderToShow(-1, null)}>{t("Back to order list") as string}</AseclaButton>
    ]

    return <div>
        <TitleBanner title={t("Details of order ") + userOrder.orderId}></TitleBanner>
        <div id='basketTable'>
            <ConstantValuesBigTableFactory
                    otherHeaderButtons={otherHeaderButtons}
                    dataSource={{other: {userOrder: userOrder}}}
                    allHeaders={allHeaders}
                    objects={userOrder.items}
                    initPageSize={100}
                    otherProps={{makeItHeight: false}}
            ></ConstantValuesBigTableFactory>
        </div>

        <OrderProperties
            aseclaAdminModule={aseclaAdminModule}
            userOrder={userOrder}
            rowData={rowData}
            modifyRowData={modifyRowData}
            orderProperties={UserOrderTableColumns(aseclaAdminModule, setOrderToConfirmInvoiceData, changeOrderToShow, [])}></OrderProperties>

        <OrderHistory aseclaAdminModule={aseclaAdminModule} userOrder={userOrder} tableHeaders={tableHeaders}></OrderHistory>
    </div>
}

export default OrderDetails;