import { LicenseType } from "../../../type/LicenseType";
import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import { ProductProperty } from "../../../type/ProductProperty";
import LicenseLimitation from "./LicenseLimitation";
import { useTranslation } from "react-i18next";
import AseclaButton from "../../utils/AseclaButton";

type LicTypePropertiesParams = {
    licType: LicenseType;
    licenseTypeIndex: number;
    modifyLicenseType: (licenseType: LicenseType, index: number) => void;
}

function LicTypeProperties({licType, licenseTypeIndex, modifyLicenseType}: LicTypePropertiesParams) {
    const {t} = useTranslation();
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [curProperty, setCurProperty] = React.useState<number>(-1);

    const freezePage = (): boolean => {
        return props.freezeHeaderCounter > 0;
    }

    const setValue = (key: string, value: string) => {
        modifyLicenseType({
            ...licType,
            properties: {
                ...licType.properties,
                [key]: value
            }
        }, licenseTypeIndex);
    }

    const deleteProp = (propId: string) => {
        if (licType.properties[propId] !== undefined) {
            const {...newProps} = licType.properties;
            delete newProps[propId];
            modifyLicenseType({
                ...licType,
                properties: newProps
            }, licenseTypeIndex);
        }
    }

    const addProperty = (property: number) => {
        setCurProperty(-1);
        modifyLicenseType({
            ...licType,
            properties: {... licType.properties, [property]: ""}
        }, licenseTypeIndex);
    }

    const setPrice = (value: string): void => {
        modifyLicenseType({
            ...licType,
            price: Number(value)
        }, licenseTypeIndex);
    }

    const licTypeArrayFieldSetter = <K extends keyof any, V extends any[K]>(key: K, lang: string) => {
        return (e: React.ChangeEvent<HTMLInputElement>|React.ChangeEvent<HTMLTextAreaElement>): void => {
            modifyLicenseType({
                ...licType,
                [key]: {
                    ...(licType as any)[key],
                    [lang]: e.target.value
                }
            }, licenseTypeIndex);
        }
    }

    const getLicenseTypePropertyLabel = (propId: string) => {
        let propIdNum = Number(propId);
        for (let i = 0; i < licType.product.properties.length; i++) {
            if (licType.product.properties[i].propertyId === propIdNum)
                return licType.product.properties[i].name;
        }
        return "???";
    }

    let unfilledProperties: ProductProperty[] = [];
    let selectedPropertyId = curProperty;
    if (licType.product != null) {
        let first: boolean = true;
        for (let key in Object.keys(licType.product.properties)) {
            let property: ProductProperty = licType.product.properties[key];
            if (licType.properties[property.propertyId] === undefined) {
                if (first && curProperty == -1) {
                    first = false;
                    setCurProperty(selectedPropertyId = property.propertyId);
                }
                unfilledProperties.push(property);
            }
        }
    }

    const fieldValueSetter = <K extends keyof LicenseType, V extends LicenseType[K]>(key: K) => {
        return (value: any): void => {
            modifyLicenseType({
                ...licType,
                [key]: value
            }, licenseTypeIndex);
        }
    }
    const fieldValueHandler = <K extends keyof LicenseType, V extends LicenseType[K]>(key: K) => {
        return (e: React.ChangeEvent<HTMLSelectElement>): void => {
            modifyLicenseType({
                ...licType,
                [key]: e.target.value as any
            }, licenseTypeIndex);
        }
    }
    return <>
        {props.getGUILangs().map((lang: string) => <div key={lang + licType.id}>
            <div>{t("Name") as string} [{lang}] <input value={licType.names[lang] ?? ""} onChange={licTypeArrayFieldSetter("names", lang)} disabled={freezePage()}/></div>
            <div>{t("Marketing description for the table") as string} [{lang}]:</div>
            <textarea value={(licType.descriptions ?? [])[lang] ?? ""} onChange={licTypeArrayFieldSetter("descriptions", lang)} disabled={freezePage()}/>
        </div>)}
        <hr></hr>
        {t("Price") as string}: <input type="number" step="0.01" value={licType.price} onChange={evt => setPrice(evt.target.value)} disabled={freezePage()}/> zł {t("per unit per user", {unit: t(licType.lengthUnit).toLowerCase()})}
        <hr></hr>
        {t("License length unit")}: <select className="small" value={licType.lengthUnit} onChange={fieldValueHandler("lengthUnit")}>
            <option value="Day">{t("Day") as string}</option>
            <option value="Month">{t("Month") as string}</option>
            <option value="Year">{t("Year") as string}</option>
        </select>
        <LicenseLimitation id={"minLength"+licType.id} label={t("Minimum length")} value={licType.minLength} setValue={fieldValueSetter("minLength")}></LicenseLimitation>
        <LicenseLimitation id={"maxLength"+licType.id} label={t("Maximum length")} value={licType.maxLength} setValue={fieldValueSetter("maxLength")}></LicenseLimitation>
        <LicenseLimitation id={"defaultLength"+licType.id} label={t("Default length")} value={licType.defaultLength} setValue={fieldValueSetter("defaultLength")} hideSwitch={true}></LicenseLimitation>
        <LicenseLimitation id={"minQuantity"+licType.id} label={t("Minimum quantity")} value={licType.minQuantity} setValue={fieldValueSetter("minQuantity")}></LicenseLimitation>
        <LicenseLimitation id={"maxQuantity"+licType.id} label={t("Maximum quantity")} value={licType.maxQuantity} setValue={fieldValueSetter("maxQuantity")}></LicenseLimitation>
        <LicenseLimitation id={"defaultQuantity"+licType.id} label={t("Default quantity")} value={licType.defaultQuantity} setValue={fieldValueSetter("defaultQuantity")} hideSwitch={true}></LicenseLimitation>
        <hr></hr>
        {Object.keys(licType.properties ?? {}).map((propId: string) => <div key={propId}>
            {getLicenseTypePropertyLabel(propId)} - <input value={licType.properties[propId]} onChange={evt => setValue(propId, evt.target.value)} disabled={freezePage()}/>
            <AseclaButton action={e => deleteProp(propId)}>{t("Delete") as string}</AseclaButton>
        </div>)}
        {unfilledProperties.length > 0 && <>
            <select className="small" value={selectedPropertyId} onChange={evt => setCurProperty(Number(evt.target.value))} disabled={freezePage()}>
                {unfilledProperties.map((property: ProductProperty) => <option key={licType.id + "_" + property.propertyId} value={property.propertyId}>
                    {property.name}
                </option>)}
            </select>
            <AseclaButton action={e => {addProperty(selectedPropertyId)}}>{t("Add Property") as string}</AseclaButton>
        </>}
    </>
}

export default LicTypeProperties;
