import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import AseclaOfferPage from "../../page/AseclaOfferPage";
import LicensesByOrderPage from "../../page/LicensesByOrders";
import ManageUsersPage from "../../page/ManageUsers";
import LicensesListPage from "../../page/LicensesListPage";
import LicensesDetailsPage from "../../page/LicensesDetailsPage";
import { OrganizationData } from "../../type/responses/OrganizationData";
import { useOrganizations } from "../../hooks/useOrganizations";
import TitleBanner from "../utils/TitleBanner";
import YouHaveLicenseToActivate from "./YouHaveLicenseToActivate";

function HomeBanner() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t} = useTranslation();
    const {organizations} = useOrganizations();

    let curOrganization : OrganizationData | null = props.currentOrganizationNo === -1 ? null : (organizations??[])[props.currentOrganizationNo];

    return <>
        <TitleBanner title={t("Welcome to ASECLA") + " " + process.env.REACT_APP_BACKEND_ENVIRONMENT}></TitleBanner>
        <section>
            {/* {!props.authentication.authenticated && <div>{t("Please log in to manage your licenses") as string}</div>} */}
        </section>

        <YouHaveLicenseToActivate></YouHaveLicenseToActivate>

        {(curOrganization == null || (!curOrganization.haveAdminRights && !curOrganization.haveManagerRights)) &&  <>
            <p>{t("elevator_speach_1") as string}</p>
            <p>{t("elevator_speach_2") as string}</p>
            <p>{t("elevator_speach_3") as string}</p>
        </>}
 
        {curOrganization != null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights) && <>
            <ButtonMenu className="button-section">
                {curOrganization != null && curOrganization.haveManagerRights && 
                            <button className="cta asecla-button" onClick={e => props.openPage(AseclaOfferPage.CONTEXT_PATH + "?tname=silver")}>{t('Buy') as string}</button>}
                {curOrganization != null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights) &&
                    <button className="cta asecla-button" onClick={e => props.openPage(ManageUsersPage.CONTEXT_PATH)}>{t('Users') as string}</button>}
                {curOrganization != null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights) &&
                    <button className="cta asecla-button" onClick={e => props.openPage(LicensesListPage.CONTEXT_PATH)}>{t('Licenses') as string}</button>}
            </ButtonMenu>
            <ButtonMenu className="button-section">
                {curOrganization != null && curOrganization.haveManagerRights && 
                        <button className="cta asecla-button" onClick={e => props.openPage(LicensesByOrderPage.CONTEXT_PATH)}>{t('Orders') as string}</button>}
                {curOrganization != null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights) &&
                        <button className="cta asecla-button" onClick={e => props.openPage("redeemCode")}>{t('Redeem code') as string}</button>}
                {curOrganization != null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights) &&
                    <button className="cta asecla-button" onClick={e => props.openPage(LicensesDetailsPage.CONTEXT_PATH)}>{t('Licenses details') as string}</button>}
            </ButtonMenu>
        </>}
    </>
}
export default HomeBanner;

const ButtonMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  button {
    flex: 1;
    height: 150px; /* Adjust height as needed */
    margin: 5px; /* Adjust margin as needed */
    font-size: 22px; /* Adjust font size as needed */
  }
`;
