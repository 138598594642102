import React from "react";
import { useTranslation } from 'react-i18next';
import { UserOrder } from "../../type/UserOrder";
import { CellProperty, HeaderDefinition, TableHeaderCell } from '../utils/bigTable/definition/HeaderDefinition';
import { LicenseType } from '../../type/LicenseType';
import { DataSource } from '../utils/bigTable/definition/DataSource';
import AseclaButton from '../utils/AseclaButton';
import PaymentDocument from '../../type/PaymentDocument';
import { useCreateLicense, useDeletePaymentDocument, useRegeneratePaymentDocument, useRequestInvoice, useSetDateOfPaymentAndAccept } from '../../hooks/mutations/useUserOrderMutations';
import { useUploadOrderDocument } from '../../hooks/mutations/useUploadOrderDocument';
import Priviliges, { LicensesRole } from '../utils/Priviliges';
import i18n from '../../i18n';
import { useConfirmProFormaSentToUser } from '../../hooks/mutations/useInvoiceDataMutations';

import searchIcon from '../../assets/searchIcon.png';
import styled from "styled-components";
import HeaderWithHint from "../utils/HeaderWithHint";
import { OrganizationData } from "../../type/responses/OrganizationData";
import InvoiceDataForm from "../InvoiceDataForm";
import { useGetOrganizationData } from "../../hooks/mutations/useOrganizationOperations";
import { t } from "i18next";
import { ContextProps } from "../../type/ContextProps";
import AseclaDataContext from "../../store/AseclaDataContext";

export const ORDER_ID: string = "ORDER_ID";
export const ORDER_TIME: string = "ORDER_TIME";
export const USER_ID: string = "USER_ID";
export const TOTAL: string = "TOTAL";

export const STATUS: string = "STATUS";
export const ORGANIZATION: string = "ORGANIZATION";
export const ORGANIZATION_DISCOUNT: string = "ORGANIZATION_DISCOUNT";
export const TOTAL_DISCOUNT: string = "TOTAL_DISCOUNT";
export const LICENSE_NAME: string = "LICENSE_NAME";
export const LICENSE_TECHNICAL_NAME: string = "LICENSE_TECHNICAL_NAME";
export const PRODUCT_NAME: string = "PRODUCT_NAME";
export const PRODUCT_TECHNICAL_NAME: string = "PRODUCT_TECHNICAL_NAME";
export const ORDER_PRICE: string = "ORDER_PRICE";
export const BONUS_CODE: string = "BONUS_CODE";
export const LICENSE: string = "LICENSE";
export const DOWNLOAD_INVOICE: string = "DOWNLOAD_INVOICE";
export const UPLOAD_DOCUMENT: string = "UPLOAD_DOCUMENT";
export const REGENERATE_INVOICE: string = "REGENERATE_INVOICE";
export const SHOW_BASKET: string = "SHOW_BASKET";
export const HIDE_BONUS_CODE: string = "HIDE_BONUS_CODE";
export const DATE_OF_PAYMENT: string = "DATE_OF_PAYMENT";
export const INVOICING_AND_PAYMENT: string = "INVOICING_AND_PAYMENT";


let OrganizationCellWithData = ({name, id}: {name: string, id: number}) => {
    const [orgToShow, setOrgToShow] = React.useState<OrganizationData|null>(null);
    let { getOrganizationData, isLoading } = useGetOrganizationData(setOrgToShow);
    
    const buttonAction = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (orgToShow === null) {
            getOrganizationData({organizationId: id});
        } else {
            setOrgToShow(null);
        }
    }
    
    return <>
        <OneLine>
            <div>{name == null ? "-" : name}</div>
            <div><AseclaButton enabled={!isLoading} action={buttonAction}>{(orgToShow === null ? t("Show") : t("Hide")) as string}</AseclaButton></div>
        </OneLine>
        {orgToShow && <div>
            <InvoiceDataForm
                taxId={orgToShow.invoiceData.taxId ?? ""} setTaxId={()=>{}}
                companyName={orgToShow.invoiceData.companyName ?? ""} setCompanyName={()=>{}}
                address={orgToShow.invoiceData.address ?? ""} setAddress={()=>{}}
                city={orgToShow.invoiceData.city ?? ""} setCity={() => {}}
                zipCode={orgToShow.invoiceData.zipCode ?? ""} setZipCode={()=>{}}
                country={orgToShow.invoiceData.country ?? ""} setCountry={()=>{}}
                readOnly={true}
                validationError={null}
            ></InvoiceDataForm>
        </div>}
    </>

}

type DateInput = {
    date?: Date,
    value: any
}
export type RowData = {
    dateOfPayment?: DateInput,
    file?: any,
    requestedByMail?: string
}
export const UserOrderTableColumns = (
          aseclaAdmin: boolean
        , setOrderToConfirmInvoiceData: (order: UserOrder|null) => void
        , setOrderToShowDetails: (rowNo: number, detailedObject: UserOrder|null) => void
        , columnsToShowByDefault?: string[]): HeaderDefinition[] => {

    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const { t } = useTranslation();
    const { createLicense } = useCreateLicense();
    const { activateLicense } = useSetDateOfPaymentAndAccept();
    const { requestInvoice } = useRequestInvoice();
    const { deletePaymentDocument } = useDeletePaymentDocument();
    const { regeneratePaymentDocument } = useRegeneratePaymentDocument();
    const { confirmProFormaSent } = useConfirmProFormaSentToUser();

    let headers: HeaderDefinition[] = [];

    let { uploadDocument } = useUploadOrderDocument();

    const defineTableHeader = (props: HeaderDefinition) => {
        if (columnsToShowByDefault != undefined) {
            if (columnsToShowByDefault.indexOf(props.headerKey) === -1) {
                props.display = false;
            }
        }
        headers.push(props);
    }

    const formatDate = (date: Date): string => {
        if (date == undefined) return "";
        let twoCharacters = (i: number): string => {
            if (i < 10) return "0" + i;
            return i + "";
        }
        return twoCharacters(date.getDate()) + "." + twoCharacters(date.getMonth()+1) + "." + date.getFullYear();
    }

    const getLicenseTypeProperty = (order: UserOrder, getValue: (licenseType: LicenseType) => any): any => {
        return (order.items === undefined || order.items[0] == undefined) ? "" : (order.items[0].licenseType ? getValue(order.items[0].licenseType) : "")
    }

    const get = (getValue: (licStat: UserOrder) => any): (cellProperty: CellProperty, dataSource: DataSource) => any => {
        return (cellProperty: CellProperty, dataSource: DataSource) => getValue(cellProperty.object);
    }

    const getLicenseColumn = (rowNo: number, order: UserOrder) => {
        if (rowNo === -1) return <></>
        if (order.status === "AwaitingPayment" || order.status === "Paid" || order.status === "Raised" || order.status === "CodeSent") {
            return <AseclaButton action={e => createLicense({orderId: order.orderId})}>{t("Generate license") as string}</AseclaButton>
        }
        return <></>
    }

    const AssignDateAndSendLicenseCell = ({rowNo, order, rowData, setRowData}:
            {rowNo: number, order: UserOrder, rowData: RowData, setRowData: (rowNo: number, row: any) => void}) => {
        const onApply = () => {
            activateLicense({
                dateOfPayment: rowData.dateOfPayment!.date!,
                orderId: order.orderId,
                organizationId: order.organization.id,
            })
        }
        const validDate = (d?: DateInput): boolean => {
            if (d == undefined) return false;
            let dt = new Date(d.value);
            return dt != undefined && dt.getFullYear() > 2000;
        }
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let inputDt: DateInput = {value: e.target.value};
            if (validDate(inputDt)) {
                inputDt.date = new Date(e.target.value);
            }
            setRowData!(rowNo, {...rowData, dateOfPayment: inputDt});
        }
        let valid = validDate(rowData.dateOfPayment);
        return <div>
            <input type="Date" value={rowData.dateOfPayment == undefined ? "" : rowData.dateOfPayment.value} onChange={onChange}/>
            <br></br>
            <AseclaButton className={valid ? "blinking" : ""} action={onApply} enabled={valid}>{t("Save date and send license") as string}</AseclaButton>
        </div>
    }
    const getDateOfPayment = (rowNo: number, order: UserOrder, rowData: RowData, setRowData: (rowNo: number, row: any) => void) => {
        if (rowNo === -1) return <></>
        if (order.invoices != undefined && order.invoices.length > 0 && (order.status === "AwaitingPayment" || order.status === "Paid" || order.status === "Raised" || order.status === "CodeSent")) {
            let invoice = order.invoices.filter(inv => inv.paymentDocumentType === "Invoice").at(-1);
            if (invoice !== undefined && invoice.dateOfPayment) {
                return <>{formatDate(invoice.dateOfPayment)}</>
            } else {
                if (aseclaAdmin) {
                    return <AssignDateAndSendLicenseCell order={order} rowData={rowData} rowNo={rowNo} setRowData={setRowData}></AssignDateAndSendLicenseCell>
                } else {
                    return <div>{t("Awaiting payment") as string}</div>
                }
            }
        }
        return <div>{t("Awaiting payment") as string}</div>
    }

    const getInvoicingAndPayment = (rowNo: number, order: UserOrder, rowData: RowData, setRowData: (rowNo: number, row: any) => void) => {
        if (rowNo === -1) return <></>
        if (order.status === "Raised") {
            if (order.invoices.filter(i => i.paymentDocumentType === "ProForma").length === 0) {
                return <>
                    <AseclaButton action={e => setOrderToConfirmInvoiceData(order)} className='light'>
                        {t("Confirm invoice data...") as string}
                    </AseclaButton>
                </>
            } else {
                return <>
                    <AseclaButton action={e => confirmProFormaSent({orderId: order.orderId})} className='light'>
                        {t("I have sent user Pro-Forma. Awaiting payment") as string}
                    </AseclaButton>
                </>
            }
        } else if (order.status === "AwaitingPayment") {
            if (order.invoices != undefined && order.invoices.length > 0) {
                return <AssignDateAndSendLicenseCell order={order} rowData={rowData} rowNo={rowNo} setRowData={setRowData}></AssignDateAndSendLicenseCell>
            }
        } else if (order.status === "Paid" || order.status === "CodeSent") {
            if (order.invoices != undefined && order.invoices.length > 0) {
                let invoice = order.invoices.filter(inv => inv.paymentDocumentType === "Invoice").at(-1);
                if (invoice !== undefined && invoice.dateOfPayment) {
                    return <><div className="hint">{t("Paid on:") as string}</div>{formatDate(invoice.dateOfPayment)} </>
                } else {
                    return <>{t("Unknown payment date") as string}</>
                }
            }
        }
        return <></>
    }

    const getInvoiceColumn = (rowNo: number, order: UserOrder) => {
        let orgId = order.organization === null ? undefined : order.organization.id;
        let getInvoice = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, payDoc: PaymentDocument): void => {
            requestInvoice({
                document: payDoc,
                req: {
                    invoiceId: payDoc.id,
                    organizationId: orgId
                }
            });
            e.preventDefault();
        }
        let delDocument = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, payDoc: PaymentDocument): void => {
            if (confirm(t('You are going to delete a Payment Document. There are no backups, are you sure?'))) {
                deletePaymentDocument({
                    paymentDocumentId: payDoc.id,
                    organizationId: orgId
                });
            }
            e.preventDefault();
        }

        if (rowNo === -1) return <></>
        if (order.status === "AwaitingPayment" || order.status === "Paid" || order.status === "Raised" || order.status === "CodeSent") {
            if (aseclaAdmin) {
                return <>
                    {(order.invoices ?? []).filter(i => i.paymentDocumentType !== "other").map(i => <div key={i.id}>
                        <AseclaButton action={e => getInvoice(e, i)}>{(i.paymentDocumentType === "ProForma" ? t("Pro-forma") : t("Invoice")) as string}</AseclaButton>
                    </div>)}
                    {(order.invoices ?? []).filter(i => i.paymentDocumentType === "other").map(i => <WithDeleteDiv key={i.id}>
                        <AseclaButton action={e => getInvoice(e, i)} className="big">{i.fileName}</AseclaButton>
                        <Priviliges roles={[LicensesRole.aseclaAdmin]}><AseclaButton action={e => delDocument(e, i)} className="icon">X</AseclaButton></Priviliges>
                    </WithDeleteDiv>)}
                </>
            } else {
                let proForma: PaymentDocument|undefined = undefined;
                let lastInvoice: PaymentDocument|undefined = undefined;
                for (let i = 0; i < (order.invoices ?? []).length; i++) {
                    let inv = order.invoices[i];
                    if (inv.paymentDocumentType === "ProForma") {
                        proForma = inv;
                    } else if (inv.paymentDocumentType === "Invoice") {
                        lastInvoice = inv;
                    }
                }
                if (!proForma && !lastInvoice) {
                    return <></>
                }
                return <>
                    {proForma && !lastInvoice && <AseclaButton action={e => getInvoice(e, proForma!)}>{t("Pro-forma") as string}</AseclaButton>}
                    {lastInvoice && <AseclaButton action={e => getInvoice(e, lastInvoice!)}>{t("Invoice") as string}</AseclaButton>}
                    {(order.invoices ?? []).filter(i => i.paymentDocumentType === "other").map(i => <div key={i.id}>
                        <AseclaButton action={e => getInvoice(e, i)}>{(i.fileName) as string}</AseclaButton>
                    </div>)}
                </>
            }
        }
        return <></>
    }

    const getUploadDocumentColumn = (rowNo: number, order: UserOrder, rowData: RowData, setRowData: (rowNo: number, row: any) => void) => {
        if (rowNo === -1) return <></>
        if (!order.organization) return <>{t("No organization")}</>
        const uploadDoc = () => {
            uploadDocument({file: rowData.file, orderId: order.orderId, organizationId: order.organization.id})
        }
        return (
            <div>
                <div>
                    <input type="file" onChange={e => setRowData(rowNo, {...rowData, file: e.target.files![0]}) } />
                    <AseclaButton action={e => uploadDoc()} enabled={rowData.file != null && rowData.file != undefined}>Upload!</AseclaButton>
                </div>
            </div>
        );
    }

    const regenerateInvoiceColumn = (rowNo: number, order: UserOrder) => {
        let regenerateInvoiceClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, payDoc: PaymentDocument): void => {            
            regeneratePaymentDocument({
                invoiceId: payDoc.id,
                organizationId: order.organization.id
            });
            e.preventDefault();
        }
        return <>
            {order.invoices.map(i => <div key={i.id}>
                {i.paymentDocumentType !== "other" && <AseclaButton action={e => regenerateInvoiceClick(e, i)}>{(i.paymentDocumentType === "ProForma" ? t("Pro-forma") : t("Invoice")) as string}</AseclaButton>}
            </div>)}
        </>
    }

    const getOrganizationDiscount = (order: UserOrder) => {
        return order.organizationDiscount + "%"
    }

    const getOrganization = (order: UserOrder) => {
        if (!aseclaAdmin || order.organization === null) {
            return order.organization === null ? "-" : order.organization.name
        }
        return <OrganizationCellWithData name={order.organization.name} id={order.organization.id}></OrganizationCellWithData>
    }

    const getTotalDiscount = (order: UserOrder) => {
        let price = order.items[0].price;

        let percentageDiscount: number = 0;
        if (order.organizationDiscount) {
            percentageDiscount = order.organizationDiscount;
        }
        if (order.items[0].bonusCodeDiscountPercentage && order.items[0].bonusCodeDiscountPercentage > percentageDiscount) {
            percentageDiscount = order.items[0].bonusCodeDiscountPercentage;
        }

        let finalDiscount = 0;
        if (percentageDiscount) {
            finalDiscount = price * percentageDiscount / (100 - percentageDiscount);
        }
        if (order.items[0].bonusCodeDiscountValue && order.items[0].bonusCodeDiscountValue > finalDiscount) {
            finalDiscount = order.items[0].bonusCodeDiscountValue;
        }
        if (finalDiscount) {
            return finalDiscount + "zł";
        }
        return "-";
    }

    

    const orderByColumn = (rowNo: number, order: UserOrder, dataSource: DataSource) => {
        return <>
            <div>{order.userEmail}</div>
            <div className="hint no-break">{order.userId}</div>
        </>
    }

    let defineHeaderCell = (key: string) : TableHeaderCell => {
        return {
            getString: () => t(key),
            getElement: () => <HeaderWithHint headerKey={key}/>
        }
    }
    let defineOrderTimeHeaderCell = () : TableHeaderCell => {
        return {
            getString: () => t("Order time"),
            getElement: () => <>{t("Order time")}<div className="subheader">dd.mm.yyyy</div></>
        }
    }

    defineTableHeader({headerKey: ORDER_ID, display: true, displayedHeader: t("Order ID"), getCellContent: get((order) => order.orderId)});
    defineTableHeader({headerKey: ORDER_TIME, display: true, displayedHeader: defineOrderTimeHeaderCell(), getCellContent: ({object}) => object.orderTime == null ? "-" : formatDate(object.orderTime)});
    defineTableHeader({headerKey: USER_ID, display: false, displayedHeader: t("Ordered by"), getCellContent: ({object, rowNo}, dataSource) => orderByColumn(rowNo, object, dataSource)});
    defineTableHeader({headerKey: TOTAL, display: true, displayedHeader: t("Total") + " " + t("net"), getCellContent: get((order) => order.items.map(item => item.price).reduce((sum, cur) => sum + (cur??0), 0) + "zł")});
    defineTableHeader({headerKey: STATUS, display: true, displayedHeader: t("Status"), getCellContent: ({object}) => object.status});
    defineTableHeader({headerKey: ORGANIZATION, display: aseclaAdmin, displayedHeader: t("Organization"), alwaysHide: !aseclaAdmin, getCellContent: ({object}) => getOrganization(object)});
    defineTableHeader({headerKey: ORGANIZATION_DISCOUNT, display: false, displayedHeader: defineHeaderCell("Organization Discount"), alwaysHide: !aseclaAdmin, getCellContent: get(object => getOrganizationDiscount(object))});
    defineTableHeader({headerKey: TOTAL_DISCOUNT, display: false, displayedHeader: defineHeaderCell("Discount"), alwaysHide: !(aseclaAdmin || props.isManager()), getCellContent: get(object => getTotalDiscount(object))});
    defineTableHeader({headerKey: LICENSE_NAME, display: aseclaAdmin, displayedHeader: t("License Name"), getCellContent: ({object}) => getLicenseTypeProperty(object, (licenseType: LicenseType) => licenseType.names[i18n.language])});
    defineTableHeader({headerKey: LICENSE_TECHNICAL_NAME, display: aseclaAdmin, alwaysHide: !aseclaAdmin, displayedHeader: t("License Technical Name"), getCellContent: ({object}) => getLicenseTypeProperty(object, (licenseType: LicenseType) => licenseType.technicalName)});
    defineTableHeader({headerKey: PRODUCT_NAME, display: aseclaAdmin, displayedHeader: t("Product Name"), getCellContent: ({object}) => getLicenseTypeProperty(object, (licenseType: LicenseType) => licenseType.product.names[i18n.language])});
    defineTableHeader({headerKey: PRODUCT_TECHNICAL_NAME, display: aseclaAdmin, alwaysHide: !aseclaAdmin, displayedHeader: t("Product Technical Name"), getCellContent: ({object}) => getLicenseTypeProperty(object, (licenseType: LicenseType) => licenseType.product.technicalName)});
    defineTableHeader({headerKey: BONUS_CODE, display: aseclaAdmin, displayedHeader: t("Used Bonus Code"), getCellContent: get((order) => order.hideBonusCode ? <i>{t("Custom") as string}</i> : order.bonusCode?.theCode)});
    defineTableHeader({headerKey: HIDE_BONUS_CODE, display: aseclaAdmin, alwaysHide: !aseclaAdmin, displayedHeader: t("Hide bonus code"), getCellContent: get((order) => order.hideBonusCode ? "Yes" : "No")});
    defineTableHeader({headerKey: LICENSE, ignoreRowAction: true, display: aseclaAdmin, alwaysHide: !aseclaAdmin, displayedHeader: t("Generate license"), getCellContent: ({object, rowNo}) => getLicenseColumn(rowNo, object)});
    defineTableHeader({headerKey: INVOICING_AND_PAYMENT, display: aseclaAdmin, ignoreRowAction: true, alwaysHide: !aseclaAdmin, displayedHeader: t("Invoicing and payment"), getCellContent: ({object, rowNo}, dataSource) => getInvoicingAndPayment(rowNo, object, dataSource.rowData![rowNo], dataSource.setRowData!)});
    defineTableHeader({headerKey: DATE_OF_PAYMENT, display: !aseclaAdmin, ignoreRowAction: true, displayedHeader: t("Date of payment"), getCellContent: ({object, rowNo}, dataSource) => getDateOfPayment(rowNo, object, dataSource.rowData![rowNo], dataSource.setRowData!)});
    defineTableHeader({headerKey: DOWNLOAD_INVOICE, ignoreRowAction: true, display: true, displayedHeader: t("Get Invoice"), getCellContent: ({object, rowNo}) => getInvoiceColumn(rowNo, object)});
    defineTableHeader({headerKey: UPLOAD_DOCUMENT, ignoreRowAction: true, display: aseclaAdmin, alwaysHide: !aseclaAdmin, displayedHeader: t("Upload document"), getCellContent: ({object, rowNo}, dataSource) => getUploadDocumentColumn(rowNo, object, dataSource.rowData![rowNo], dataSource.setRowData!)});
    defineTableHeader({headerKey: REGENERATE_INVOICE, ignoreRowAction: true, display: aseclaAdmin, alwaysHide: !aseclaAdmin, displayedHeader: t("Regenerate invoice"), getCellContent: ({object, rowNo}) => regenerateInvoiceColumn(rowNo, object)});    
    defineTableHeader({headerKey: SHOW_BASKET, ignoreRowAction: true, display: true, displayedHeader: t("Details"), getCellContent: (({object, rowNo}) => rowNo === -1 ? <></> : <AseclaButton action={e => setOrderToShowDetails(rowNo, object)}><img src={searchIcon} style={{background: "white", width: "25px", height: "25px"}} alt={t("Show details") as string}/></AseclaButton>)});

    return headers;
}

let WithDeleteDiv = styled.div`
    display: flex;
    button.big {
        margin-right: -20px;
    }
    button.icon {
        width: 20px;
    }
`;

let OneLine = styled.div`
    white-space: nowrap;
    > * {
        display: inline-block;
    }
`