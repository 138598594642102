import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { getDefaultRequestSettings, requestServerPOST } from "../../store/ServerConnection";
import { GetPriceResponse } from "../../type/responses/GetPriceResponse";
import GetPriceRequest from "../../type/request/GetPriceRequest";
import { t } from "i18next";

interface usePriceParams {
    bonusCode: string|null,
    length: number,
    lengthUnit: string,
    licenseTypeId: number,
    numberOfUsers: number,
}
export function usePrice(setPrice: (ret: GetPriceResponse) => void, includeOrganizationDiscount: boolean) {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    
    const { mutate: getPrice, isError: priceError, isSuccess: priceSuccess } = useMutation({
        mutationFn: async ({bonusCode, length, lengthUnit, licenseTypeId, numberOfUsers}: usePriceParams) => {
            let body: GetPriceRequest = {
                bonusCode: bonusCode,
                length: length,
                lengthUnit: lengthUnit,
                licenseTypeId: licenseTypeId,
                numberOfUsers: numberOfUsers,
                organizationId: includeOrganizationDiscount ? props.getCurrentOrganization()?.id : undefined,
            };
            return requestServerPOST("getPrice", body, props, {...getDefaultRequestSettings(), freezeGUI: false});
        },
        onSuccess: (resp: GetPriceResponse|null) => {
            if (resp != null) setPrice(resp);
        },
        onError: (error: any, variables: usePriceParams) => {
            console.log(error);
            console.log("Can't load price");
            console.log(variables);
            serverError(t("Error while loading price"))
        }
    });
  
    return { getPrice, priceError, priceSuccess };
}
