import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { getDefaultRequestSettings, ServerRequestSettings, requestServerPOST } from "../../store/ServerConnection";
import CreateLicenseFromOrderRequest from "../../type/request/CreateLicenseFromOrderRequest";
import { queryClient } from "../../App";
import { TANSTACK_USER_ORDERS_KEY } from "../useUserOrders";
import OrdersResponse, { handleUserOrdersResponse } from "../../type/responses/OrdersResponse";
import GetInvoiceRequest from "../../type/request/GetInvoiceRequest";
import { t } from "i18next";
import PaymentDocument from "../../type/PaymentDocument";
import { apiErrorHandling } from "../useErrorHandling";
import RegenerateInvoiceRequest from "../../type/request/RegenerateInvoiceRequest";

export function useCreateLicense() {
    const { serverSuccess } = Notifications();
    const {mutationWithErrHandling } = apiErrorHandling({
        errMsg: t("Error while creating license") as string,
        createRequest: ({orderId}: any) => {
            let body: CreateLicenseFromOrderRequest = {
                orderId: orderId,
            };
            return body;
        }
    });
    
    const { mutate: createLicense} = mutationWithErrHandling("createLicenseFromOrder",
        () => {
            serverSuccess(t("License created"));
        }
    );
  
    return { createLicense };
}


export function useSetDateOfPaymentAndAccept() {
    const { serverSuccess } = Notifications();
    const {mutationWithErrHandling } = apiErrorHandling({
        errMsg: t("Error while activating license")
    });

    const { mutate: activateLicense} = mutationWithErrHandling("setDateOfPaymentAndAccept",
        (ret: OrdersResponse) => {
            queryClient.invalidateQueries([TANSTACK_USER_ORDERS_KEY]);
            serverSuccess(t("License has been activated"));
        }
    );
  
    return { activateLicense };
}

export interface RequestInvoiceMutationParams {
    req: GetInvoiceRequest,
    document: PaymentDocument
}
export function useRequestInvoice() {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;

    const { mutate: requestInvoice } = useMutation({
        mutationFn: (reqParam: RequestInvoiceMutationParams) => {
            if (!props.authentication.initialized() || !props.authentication.isAuthenticated()) {
                return new Promise(() => null);
            }
            let requestSettings: ServerRequestSettings = {
                ...getDefaultRequestSettings(),
                parseJSONResponse: false,
            }
            return requestServerPOST("requestInvoice", reqParam.req, props, requestSettings);
        },
        onSuccess: (resp: any, variables: RequestInvoiceMutationParams) => {
            resp.blob().then((value: any) => {
                const url = window.URL.createObjectURL(new Blob([value]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', variables.document.fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
            })
        },
        onError: (error: any, variables: any) => {
            console.log(error);
            console.log(variables);
            serverError(t("Error getting invoice"))
        }
    });
  
    return { requestInvoice };
}

export function useDeletePaymentDocument() {
    const { serverSuccess } = Notifications();
    const { mutationWithErrHandling } = apiErrorHandling({
        errMsg: t("Error while deleting document")
    });

    const { mutate: deletePaymentDocument} = mutationWithErrHandling("deletePaymentDocument", 
        (ret: any) => {
            queryClient.invalidateQueries([TANSTACK_USER_ORDERS_KEY]);
            serverSuccess(t("Document deleted"))
        }
    );
  
    return { deletePaymentDocument };
}

export function useRegeneratePaymentDocument() {
    const { serverSuccess } = Notifications();
    const { mutationWithErrHandling } = apiErrorHandling({
        errMsg: t("Faild to regenerate payment document"),
        createRequest: ({invoiceId}: any) => {
            let body: RegenerateInvoiceRequest = {
                invoiceId: invoiceId,
            };
            return body;
        }
    });

    const { mutate: regeneratePaymentDocument} = mutationWithErrHandling("regenerateInvoice", 
        (ret: any) => {
            queryClient.invalidateQueries([TANSTACK_USER_ORDERS_KEY]);
            serverSuccess(t("Invoice regenerated"))
        }
    );
  
    return { regeneratePaymentDocument };
}
