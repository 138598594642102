import React from "react";
import AseclaDataContext from "../store/AseclaDataContext";
import { ContextProps } from "../type/ContextProps";
import { useTranslation } from "react-i18next";
import InvoiceData, { getCountryList } from "../type/InvoiceData";
import { ValidationError, getValidationIssues } from "../type/validation/ValidationError";
import { ValidationIssue } from "../type/validation/ValidationIssue";
import { t } from "i18next";
import styled from "styled-components";

interface InvoiceDataFormProps {
    companyName: string, setCompanyName: (val: string) => void,
    taxId: string, setTaxId: (val: string) => void,
    address: string, setAddress: (val: string) => void,
    city: string, setCity: (val: string) => void,
    zipCode: string, setZipCode: (val: string) => void,
    country: string, setCountry: (val: string) => void,
    validationError: ValidationError|null,
    readOnly?: boolean
    children?: JSX.Element[]
}
function InvoiceDataForm(p: InvoiceDataFormProps) {
    return <AutoMargin>
        <ConfigurationContainer>
            {p.children}
            <Field techName={"companyName"} label={"Company name"} value={p.companyName} setter={p.setCompanyName} validationError={p.validationError} readOnly={p.readOnly}></Field>
            <Field techName={"taxId"} label={"Tax ID"} value={p.taxId} setter={p.setTaxId} validationError={p.validationError} readOnly={p.readOnly}></Field>
            <Field techName={"address"} label={"Address"} value={p.address} setter={p.setAddress} validationError={p.validationError} readOnly={p.readOnly}></Field>
            <Field techName={"city"} label={"City"} value={p.city} setter={p.setCity} validationError={p.validationError} readOnly={p.readOnly}></Field>
            <Field techName={"zipCode"} label={"ZIP code"} value={p.zipCode} setter={p.setZipCode} validationError={p.validationError} readOnly={p.readOnly}></Field>
            <Dropdown techName={"country"} label={"Country"} value={p.country} setter={p.setCountry} 
                validationError={p.validationError} readOnly={true} values={getCountryList()} defaultVal="Poland"></Dropdown>
        </ConfigurationContainer>
    </AutoMargin>
}
export default InvoiceDataForm;

interface FieldParams {
    techName: string,
    label: string,
    setter: (val: any) => void,
    value: any,
    validationError: ValidationError|null,
    readOnly?: boolean
}
function Field({techName, label, setter, value, validationError, readOnly}: FieldParams) {
    const {t} = useTranslation();
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    return <>
        <label htmlFor={techName + "New"}>{t(label) as string}</label>
        <input name={techName + "New"} id={techName + "New"} value={value??""} onChange={(e) => setter(e.target.value)} disabled={readOnly || props.freezeHeaderCounter > 0}/>
        <ValidationMessage validationError={validationError} field={techName} label={t(label) as string}></ValidationMessage>
    </>
}

function camelize(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word: string, index: number) {
        return word.toUpperCase();
    }).replace(/\s+/g, '');
}
interface DropdownParams {
    techName: string,
    label: string,
    setter: (val: any) => void,
    value: any,
    validationError: ValidationError|null,
    values: string[],
    readOnly?: boolean,
    defaultVal?: string,
}
function Dropdown({techName, label, setter, value, validationError, values, readOnly, defaultVal}: DropdownParams) {
    const {t} = useTranslation();
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    let selected: string = camelize(value??"");
    selected = (values.indexOf(selected) != -1 ? selected : defaultVal) ?? "";

    React.useEffect(() => {
        if (values.indexOf(value) == -1) {
            setter(selected);
        }
    }, []);

    return <>
        <label htmlFor={techName + "New"}>{t(label) as string}</label>
        <select name={techName + "New"} id={techName + "New"} value={selected??""} onChange={(e) => setter(e.target.value)} disabled={readOnly || props.freezeHeaderCounter > 0}>
            {values.map(v => <option key={v} value={v}>{t(v) as string}</option>)}
        </select>
        <ValidationMessage validationError={validationError} field={techName} label={t(label) as string}></ValidationMessage>
    </>
}

interface ValidationMessageParams {
    validationError: ValidationError|null,
    field: string,
    label: string
}
function ValidationMessage({validationError, field, label}: ValidationMessageParams) {
    let issues: ValidationIssue[]|null = null;
    if (validationError != null) {
        issues = getValidationIssues(validationError, field);
    }
    return <>{issues != null && issues.map((issue, i) => <FieldError key={i}>
        {issue.errCode == 1 && t("Please provide {{label}}", {label: label}) as string}
        {issue.errCode == 2 && t("The {{label}} should be in format of {{pattern}}", {label: label, pattern: (issue as any).pattern}) as string}
    </FieldError>)}</>
}


interface InvoiceDataFormOnInvoiceDataParams {
    invoiceData: InvoiceData,
    setInvoiceData: (invoiceData: InvoiceData) => void,
    validationError: ValidationError|null
}
export function InvoiceDataFormOnInvoiceData({ invoiceData, setInvoiceData, validationError} : InvoiceDataFormOnInvoiceDataParams) {
    const setValue = (key: keyof InvoiceData) => {
        return (value: string) => {
            setInvoiceData({
                ...invoiceData!,
                [key]: value
            })
        }
    }
    return <InvoiceDataForm
        address={invoiceData.address} setAddress={setValue("address")}
        taxId={invoiceData.taxId} setTaxId={setValue("taxId")}
        city={invoiceData.city} setCity={setValue("city")}
        companyName={invoiceData.companyName} setCompanyName={setValue("companyName")}
        country={invoiceData.country} setCountry={setValue("country")}
        zipCode={invoiceData.zipCode} setZipCode={setValue("zipCode")}
        validationError={validationError}
    ></InvoiceDataForm>
}


let ConfigurationContainer = styled.div `
    display: grid;
    justify-content: center;
    grid-template-columns: max-content max-content;
    grid-gap: 5px;

    label { text-align: right; }
    label:after { content: ":"; }
`;

let AutoMargin = styled.div `
    margin: auto;
`;

let FieldError = styled.div `
	font-size: smaller;
	color: red;
	grid-column-start: 1;
	grid-column-end: 3;
`;