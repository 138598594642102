import React from "react";
import { t } from "i18next";
import { ValidationIssue } from "../type/validation/ValidationIssue";
import { ValidationError } from "../type/validation/ValidationError";
import { Notifications } from "../integration/Notifications";
import { useMutation } from "@tanstack/react-query";
import AseclaDataContext from "../store/AseclaDataContext";
import { ContextProps } from "../type/ContextProps";
import { requestServerPOST } from "../store/ServerConnection";

interface apiErrorHandlingParams {
    errMsg: string
    createRequest?: (reqParams: any) => any
}
export function apiErrorHandling({errMsg, createRequest}: apiErrorHandlingParams) {
    const { serverError } = Notifications();
    const isSuccess = (response: any): boolean => {
        if (response == null) {
            serverError(errMsg);
            return false;
        }
        if (response.success) return true;
        if (Object.hasOwn(response, "errorCode")) {
            if (response.errorCode == 1) {
                //Validation failed - details in issue array
                let resp: ValidationError = response;
                for (let i = 0; i < (resp.issues ?? []).length; i++) {
                    let issue: ValidationIssue = resp.issues[i];
                    let msg = "Error while";
                    if (issue.errCode == 1) {
                        msg = t("Please provide {{label}}", {label: issue.field});
                    } else if (issue.errCode == 2) {
                        msg = t("The {{label}} should be in format of {{pattern}}", {label: issue.field, pattern: (issue as any).pattern});
                    } 
                    serverError(msg);
                }
            } else if (response.errorCode == 2) {
                //It's a redeem code error - details in errCode parameter
                if (response.errCode == 1) {
                    serverError(t("Could not redeem the code"))
                } else if (response.errCode == 2) {
                    serverError(t("Redeem failed - License code has been already activated before"));
                } else if (response.errCode == 3) {
                    serverError(t("No active licenses left for this license code"));
                } else {
                    serverError(t("Code has not been redeemed"));
                }
            } else {
                console.log("Uknown server error:" + response.errorCode);
                serverError(errMsg);
            }
        } else {
            serverError(errMsg);
        }
        return false;
    }

    const successHandler = (runOnSucces: (response1: any, variables1: any, context1: any) => void) => {
        return (response: any, variables: any, context: any) => {
            if (isSuccess(response)) {
                runOnSucces(response, variables, context);
            }
        }
    }

    const mutationWithErrHandling = (endpoint: string, onSuccessAction: (ret: any) => void) => {
        const props = React.useContext(AseclaDataContext) as ContextProps;

        return useMutation({
            mutationFn: async (req: any) => {
                if (!props.authentication.initialized() || !props.authentication.isAuthenticated()) {
                    return new Promise(() => null);
                }
                let r = req;
                if (createRequest != undefined) {
                    r = createRequest(r);
                }
                return requestServerPOST(endpoint, r, props);
            },
            onSuccess: successHandler(onSuccessAction),
            onError: (error: any, variables: any) => {
                console.log(error);
                console.log(variables);
                serverError(errMsg)
            }
        });
    }

    return {isSuccess, successHandler, mutationWithErrHandling};
}