import { useQuery } from "@tanstack/react-query";
import { getUrl, prepareHeadersPromise } from "../../store/ServerConnection";
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { LicenseCode } from "../../type/LicenseCode";
import { handleLicenseCodes } from "../../type/responses/GetAllLicenseCodesResponse";

export const LICENSE_CODES_FULL_LIST = ["LICENSE_CODES_FULL_LIST"];
interface useLicenseCodesResult {
    licenseCodes: LicenseCode[],
    isLoading: boolean,
    isError: boolean,
}
export function useLicenseCodes(): useLicenseCodesResult {
    const props = React.useContext(AseclaDataContext);

    let query = useQuery<LicenseCode[]>({
        queryKey: LICENSE_CODES_FULL_LIST,
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            const requestOptions = {
                method: 'GET',
                headers: await prepareHeadersPromise(null, props.authentication),
            };
            let response = await fetch(getUrl("getAllLicenseCode"), requestOptions);
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return await response.json().then(r => handleLicenseCodes(r.licenseCodes));
        }
    });

    return {
        licenseCodes: query.data ?? [],
        isLoading: query.isLoading,
        isError: query.isError,
    }
}
