import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import { Trans, useTranslation } from 'react-i18next';
import { BonusCode, handleBonusCodeDates } from "../../../type/BonusCode";
import AseclaButton from "../../utils/AseclaButton";
import Multiselect from 'multiselect-react-dropdown';
import { dateToString } from "../../utils/functions";
import { useAdminProducts } from "../../../hooks/queries/useAdminProducts";
import Loading from "../../utils/Loading";
import IsErrorMessage from "../../utils/IsErrorMessage";
import { useDeleteBonusCode, useSaveBonusCode } from "../../../hooks/mutations/useAdminBonusCodeItem";
import './BonusCodeItem.css';
import Hint from "../../utils/Hint";
import { useAdminBonusCodes } from "../../../hooks/queries/useAdminBonusCodes";

type BonusCodeParams = {
    bonusCode: BonusCode,
    index: number,
    saveCode: (bonusCode: BonusCode) => void
    deleteCode: () => void
}
function BonusCodeItem({bonusCode, index, saveCode, deleteCode}: BonusCodeParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const {t } = useTranslation();
    const [expanded, setExpanded] = React.useState<boolean>(bonusCode.id == -1);
    const { bonusCodes: allBonusCodes } = useAdminBonusCodes(true);

    const duplicateBonusCode = (bc: BonusCode): BonusCode => {
        let dup: BonusCode = {...bonusCode};
        if (bc.products) {
            dup.products = [...bc.products];
        } else {
            dup.products = [];
        }
        dup.bonusDescription = {... bc.bonusDescription};
        return dup;
    }
    const [original, setOriginal] = React.useState<BonusCode>(duplicateBonusCode(bonusCode));

    const { products, isFetching, isError } = useAdminProducts();

    const onSaved = (bonusCode: BonusCode) => {
        let newBonusCode = handleBonusCodeDates(bonusCode);
        setOriginal(newBonusCode);
        saveCode(newBonusCode);
    }
    const { saveBonusCode } = useSaveBonusCode(bonusCode, onSaved);
    const { deleteBonusCode } = useDeleteBonusCode(deleteCode);


    let isDifferent = JSON.stringify(bonusCode) != JSON.stringify(original);

    const setDiscountType = (type: string) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        bc.discountType = type;
        saveCode(bc);
    }
    const setDiscountCode = (theCode: string) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        bc.theCode = theCode;
        saveCode(bc);
    }
    const setBonusCodeValue = (fieldName: string, value: any): BonusCode => {
        let bc: any = duplicateBonusCode(bonusCode);
        bc[fieldName] = value;
        saveCode(bc);
        return bc;
    }
    const setBonusCodeDescription = (lang: string, value: string) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        bc.bonusDescription[lang] = value;
        saveCode(bc);
    }

    let productValues: any[] = [];
    let allProducts: any[] = [];
    for (let i = 0; i < products.length; i++) {
        let prd = {name: products[i].names['en'], id: products[i].id, index: i}
        if (bonusCode.products && bonusCode.products.indexOf(products[i].id) != -1) {
            productValues.push(prd);
        }
        allProducts.push(prd);
    }

    const onSelect = (selectedList: any, selectedItem: any) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        bc.products.push(selectedItem.id);
        saveCode(bc);
    }
    const onRemove = (selectedList: any, removedItem: any) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        let index = bc.products.indexOf(removedItem.id);
        bc.products.splice(index, 1);
        saveCode(bc);
    }

    let isUsed: boolean = (bonusCode.noOfUsage != null && bonusCode.noOfUsage > 0);

    const nameIsUsed = (name: string): boolean => {
        return allBonusCodes.filter(bc => 
                bc.id != bonusCode.id 
             && bc.theCode == name
             && (bc.validUntil == null || bc.validUntil.getTime() > new Date().getTime())
        ).length > 0;
    }

    const onSave = (e:  React.MouseEvent<HTMLButtonElement>) => {
        if (nameIsUsed(bonusCode.theCode || "")) {
            alert(t("There is other Bonuscode with this Name (code), please select different code"));
            return;
        }
        if (isUsed || bonusCode.finalVersion) {
            if (!window.confirm(t("You are saving __ bonus code. Are you sure?", {props: (isUsed ? t("used ") : "") + (isUsed && bonusCode.finalVersion ? t("and ") : "") + (bonusCode.finalVersion ? t("final ") : "")}))) {
                return;
            }
        }

        saveBonusCode(null);
    }

    const onIsFinalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBonusCodeValue("finalVersion", e.target.checked);
        if (e.target.checked) {
            setTimeout(() => {saveBonusCode(null);}, 10);
        }
    }


    if (isFetching) return <Loading></Loading>
    if (isError) return <IsErrorMessage></IsErrorMessage>


    return <div bonus-code-id="bonusCode.id" className={expanded ? "ExpandedBonusCode" : ""}>
        ({"id: " + bonusCode.id + "; " + (isUsed ? t("UsedTimes", {no: bonusCode.noOfUsage}) : t("Not used yet"))})<AseclaButton action={e => setExpanded(!expanded)}>{t(expanded ? "Less information" : "More information") as string}</AseclaButton>
        {!expanded && <>{t("Bonus Code") as string}: {bonusCode.theCode}</>}
        {expanded && <>
            <AseclaButton action={onSave} className={isDifferent ? "blinking" : ""}>{t("Save") as string}</AseclaButton>
            {!isUsed && !original.finalVersion && <AseclaButton action={e => deleteBonusCode({bonusCodeId: bonusCode.id})}>{t("Delete") as string}</AseclaButton>}
            <input type="checkbox" name={"isFinal" + bonusCode.id} id={"isFinal" + bonusCode.id} checked={bonusCode.finalVersion} onChange={onIsFinalChange} disabled={isDifferent && bonusCode.finalVersion}/>
            <label htmlFor={"isFinal" + bonusCode.id}>{t("isFinal") as string} </label>
            <Hint content={t("We mark bonus code as final to mark ourselfs this bonuscode is not to be changed anytmore.")}></Hint>
            <input type="checkbox" name={"isActive" + bonusCode.id} id={"isActive" + bonusCode.id} checked={bonusCode.active} onChange={e => setBonusCodeValue("active", e.target.checked)}/><label htmlFor={"isActive" + bonusCode.id}>{t("Active") as string} </label>
            <Hint content={t("Code will work for user only if it's active.")}></Hint>
            <div data-testid="codeString">
                
                {t("Bonus Code") as string + ": "}
                {(!isUsed && !bonusCode.finalVersion) && <input type="text" value={bonusCode.theCode||""} onChange={e => setDiscountCode(e.target.value)}/>}
                {(isUsed || bonusCode.finalVersion) && bonusCode.theCode||""}
                <Hint content={t("Kod można zmieniać póki nie jest używany oraz nie ma flagi 'final Version'")}></Hint>
            </div>
            <div>
                {!bonusCode.finalVersion && <>
                    <input type="radio" name={"value_perc_" + index} value="Percentage" onChange={e => setDiscountType("Percentage")} checked={bonusCode.discountType==="Percentage"} />{t("Percent discount") as string}
                    <input type="radio" name={"value_const_" + index} value="ConstantValue" onChange={e => setDiscountType("ConstantValue")} checked={bonusCode.discountType==="ConstantValue"}/>{t("Constant value discount") as string}
                    <input type="number" value={bonusCode.value||""} onChange={e => setBonusCodeValue("value", Number(e.target.value))} />
                </>}
                {bonusCode.finalVersion && <>
                    {bonusCode.discountType==="Percentage" && t("Percent discount")}
                    {bonusCode.discountType==="ConstantValue" && t("Constant value discount")}
                    {bonusCode.value||""}
                </>}
                {bonusCode.discountType == "Percentage" && <>%</>}
                {bonusCode.discountType == "ConstantValue" && <>zł</>}
            </div>
            <div>
                {/* https://stackoverflow.com/questions/30190588/html-select-multiple-as-dropdown */}
                {bonusCode.finalVersion && <>
                    {(bonusCode.limitNoOfUsage == null || bonusCode.limitNoOfUsage == 0) && t("Bez limitu liczby użyć")}
                    {(bonusCode.limitNoOfUsage != null && bonusCode.limitNoOfUsage > 0) && t("Limit liczby użyć: ", {usageLimit: bonusCode.limitNoOfUsage})}
                </>}
                {!bonusCode.finalVersion && <>
                    <Trans i18nKey={"limitUsageTo"}
                          components={{limit: <input type="number" value={bonusCode.limitNoOfUsage||""} onChange={e => setBonusCodeValue("limitNoOfUsage", Number(e.target.value))}/>}}/>
                </>}
                <br></br>
                {bonusCode.finalVersion && <>
                    {bonusCode.validUntil == null && t("Bez ograniczeń czasowych")}
                    {bonusCode.validUntil != null && t("Bonuscode valid until") + ": " + bonusCode.validUntil }
                </>}
                {!bonusCode.finalVersion && <>
                    {t("Bonuscode valid until") as string}: <input type="date" value={dateToString(bonusCode.validUntil)} onChange={e => {setBonusCodeValue("validUntil", new Date(e.target.value))}}/>
                </>}
            </div>


            <Multiselect
                options={allProducts} // Options to display in the dropdown
                selectedValues={productValues} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder={t("Select applicable products")}
                disable={bonusCode.finalVersion}
            />

            <div>
                {t("Bonus Codes user description") as string}:
                {props.getGUILangs().map((lang: string) => <div key={"lang_" + lang + "_" + index}>
                    {lang}: <input type="text" value={bonusCode.bonusDescription[lang] ?? ""} onChange={e => setBonusCodeDescription(lang, e.target.value)}/>
                </div>)}
            </div>
        </>}
    </div>
}
export default BonusCodeItem;