import { ContextProps } from "../type/ContextProps";
import { isValidationIssue } from "../type/validation/ValidationError";
import { Authentication } from "./AuthenticationPageBase";

export const getBEServerUrl = (): string => {
    let environment = {
        protocol: process.env.REACT_APP_BACKEND_SERVER_PROTOCOL,
        server: process.env.REACT_APP_BACKEND_SERVER_DOMAIN,
        port: process.env.REACT_APP_BACKEND_SERVER_PORT,
        path: process.env.REACT_APP_BACKEND_SERVER_PATH
    }

    return environment.protocol + "://" + environment.server 
        + (environment.port ? ":" + environment.port : "") 
        + (environment.path ? "/" + environment.path : "")
}
export const getUrl = (action : string): string => {
    return getBEServerUrl() + "/" + action;
}
export const prepareHeadersPromise = (otherHeaders: any, authentication?: Authentication) : Promise<any> => {
    return new Promise((resolve, reject) => {
        let headers : any = {
            'Content-Type': 'application/json',
        };
        if (otherHeaders != null) {
            for (const [key , value] of Object.entries(otherHeaders)) {
                headers[key] = value;
            }
        }
        if (authentication != undefined && authentication.isAuthenticated()) {
            let addToken = (token: string|null) : any => {
                if (token !== null) {
                    headers["Authorization"] = "Bearer " + token;
                }
                resolve(headers);
            };

            authentication.provideTokenIfAuthenticated()
                .then(addToken)
                .catch(err => console.log(err));
        } else {
            resolve(headers);
        }
    });
}

export interface ServerRequestSettings {
    freezeGUI: boolean,
    jsonStringify: boolean,
    parseJSONResponse: boolean,
    contentType?: string
}
export const getDefaultRequestSettings = (): ServerRequestSettings => {
    return {
        freezeGUI: true,
        jsonStringify: true,
        parseJSONResponse: true,
        contentType: 'application/json'
    }
}
export const requestServerPOST = async (action: string, requestData: any, props: ContextProps, settings: ServerRequestSettings = getDefaultRequestSettings()) : Promise<any> => {
    return requestServer('POST', action, requestData, props, settings);
}
export const requestServer = async (method: string, action: string, requestData: any, props: ContextProps, settings: ServerRequestSettings = getDefaultRequestSettings()) : Promise<any> => {
    if (settings.freezeGUI) props.freezeHeader(true);
    let headers : any = {};
    if (settings.contentType != undefined) {
        headers['Content-Type'] = settings.contentType;
    }
    let token = await props.authentication.provideTokenIfAuthenticated();
    if (props.authentication.isAuthenticated() && token != null) {
        headers["Authorization"] = "Bearer " + token;
    }
    const requestOptions = {
        method: method,
        headers: headers,
        body: settings.jsonStringify ? JSON.stringify(requestData) : requestData
    };

    return fetch(getUrl(action), requestOptions)
        .then(async ret => {
            if (settings.freezeGUI)
                props.freezeHeader(false);

            const contentType = ret.headers.get('Content-Type') || '';
            let responseData;
            if (settings.parseJSONResponse) {
                if (contentType.includes('application/json')) {
                    responseData = await ret.json();
                } else {
                    responseData = await ret.text();
                }
            } else {
                responseData = ret;
            }
            if (!ret.ok) {
                if (!isValidationIssue(responseData)) {
                    const errorMessage = (settings.parseJSONResponse
                        ? (typeof responseData === 'string' ? responseData : responseData.message) : false)
                        || `Error ${ret.status}`
                    throw new Error(errorMessage);
                }
            }
            return responseData;
        })
        .catch(err => {if (settings.freezeGUI) props.freezeHeader(false); throw err;});
}

export interface ServerConnection {
    prepareHeadersPromise(headers: any, authentication: Authentication) : Promise<any>;
}

export const serverConnection = () => {
    return {
        prepareHeadersPromise,
    }
}