import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useTranslation } from 'react-i18next';
import { LicenseType } from "../../type/LicenseType";
import { BonusCode } from "../../type/BonusCode";
import VerifyBonusCodeResponse from "../../type/responses/VerifyBonusCodeResponse";
import AseclaButton from "./AseclaButton";
import { requestServerPOST } from "../../store/ServerConnection";
import VerifyBonusCodeRequest from "../../type/request/VerifyBonusCodeRequest";
import { OrganizationData } from "../../type/responses/OrganizationData";
import UserInputAction from "./UserInputAction";

type BonusCodeElementParams = {
    licType: LicenseType,
    bonusCodeToForce?: BonusCode,
    setBonusCodeEnableBuy: (enable: boolean) => void,
    setBonusCodeValid: (valid: boolean) => void,
    setBonusCode?: (bonusCode: BonusCode | null) => void,
    bonusCode: BonusCode | null,
    includeConsent?: boolean,
    includeBonusCodeInput?: boolean,
    newLayout?: boolean,
    bonusCodeString: string, setBonusCodeString: React.Dispatch<React.SetStateAction<string>>,
    bonusCodeConsent?: boolean, setBonusCodeConsent?: React.Dispatch<React.SetStateAction<boolean>>,
    lastVerifiedBonusCode?: string, setLastVerifiedBonusCode: React.Dispatch<React.SetStateAction<string|undefined>>,
}
function BonusCodeElement({licType, setBonusCodeEnableBuy, setBonusCodeValid, bonusCodeToForce, bonusCode, setBonusCode, includeConsent = true
            , includeBonusCodeInput = true, newLayout = false
            , bonusCodeString, setBonusCodeString
            , bonusCodeConsent = false, setBonusCodeConsent
            , lastVerifiedBonusCode, setLastVerifiedBonusCode} : BonusCodeElementParams) {    
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t} = useTranslation();

    let showVerify = (bonusCodeString !== "" && (bonusCodeString !== lastVerifiedBonusCode));
    let showBonusCodeMsg = bonusCodeString === lastVerifiedBonusCode;

    React.useEffect(() => {
        setBonusCodeEnableBuy((bonusCodeString === "") || ((bonusCodeToForce?.theCode === bonusCodeString || showBonusCodeMsg) && bonusCode !== null && (!includeConsent || bonusCodeConsent)));
        setBonusCodeValid((bonusCode !== null || bonusCodeToForce != undefined) && bonusCodeString === (bonusCodeToForce?.theCode ?? bonusCode?.theCode));
    }, [bonusCodeString, bonusCodeConsent, bonusCode])

    const onBonusCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newCode = e.target.value;
        onBonusCodeTextChange(newCode);
    }

    const onBonusCodeTextChange = (newCode: string): void => {
        setBonusCodeString(newCode);
    }

    const verifyBonusCode = (licenseType: LicenseType) => {
        let org: OrganizationData | null = props.getCurrentOrganization();
        let req: VerifyBonusCodeRequest = {"bonusCodeText": bonusCodeString, licenseId: licenseType.id, organizationId: org == null ? 0 : org.id};
        requestServerPOST("verifyBonusCode", req, props).then((ret: VerifyBonusCodeResponse) => {
            setLastVerifiedBonusCode(bonusCodeString);
            if (setBonusCode != undefined) {
                setBonusCode(ret.bonusCode);
            }
            setBonusCodeValid(ret.bonusCode !== null);
        });
    }

    const onConsentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (setBonusCodeConsent !== undefined) {
            setBonusCodeConsent(e.target.checked);
        }
    }

    return <>    
        {includeBonusCodeInput && <>
            {newLayout && <div>{t("Enter gift card or promo code if you have") as string}</div>}
            
            {newLayout ? "" : <>{t("Would you like to provide bonus code")} <input id="bonusCodeInput" type="text" value={bonusCodeString} onChange={onBonusCodeChange} disabled={bonusCodeToForce != undefined || props.freezeHeaderCounter > 0}></input></>}
            {showVerify && !newLayout && <AseclaButton id="verifyBonusCode" action={(e) => verifyBonusCode(licType)}>{t("Verify") as string}</AseclaButton>}
            {newLayout && <UserInputAction
                        value={bonusCodeString}
                        buttonAction={(e:any) => verifyBonusCode(licType)}
                        buttonText={t("Activate")}
                        onValueChange={onBonusCodeTextChange}
                        disableButton={(s) => !showVerify}
                        inputId="bonusCodeInput"
                        buttonId="verifyBonusCode"
                      ></UserInputAction>}

            {showBonusCodeMsg && <>
                <br></br>
                {bonusCode === null && <i id="bonusCodeIncorrect">{t("Provided bonus code is incorrect") as string}</i>}
                {bonusCode !== null && <i id="bonusCodeCorrect">{t("Bonus code correct")}: {bonusCode.bonusDescription["en"]}</i>}
            </>}
        </>}
        {includeConsent && bonusCode !== null && bonusCodeString === bonusCode.theCode && <div>
            <input type="checkbox" disabled={bonusCodeString === "" || props.freezeHeaderCounter > 0} checked={bonusCodeConsent} onChange={onConsentChange} name="bonusCodeConsent" id="bonusCodeConsent"/>
            &nbsp;
            <label htmlFor="bonusCodeConsent">{t("I have read the terms of the promotion") as string}</label>
        </div>}
    </>
}
export default BonusCodeElement;