import { ContextProps } from "../type/ContextProps";
import { useTranslation } from 'react-i18next';
import ManageUsersPage from "../page/ManageUsers";
import LicensesByOrderPage from "../page/LicensesByOrders";
import { OrganizationData } from "../type/responses/OrganizationData";
import LicensesDetailsPage from "../page/LicensesDetailsPage";
import AseclaAdminProductsPage from "../page/AseclaAdminProductsPage";
import AseclaAdminLicTypePage from "../page/AseclaAdminLicTypePage";
import AseclaAdminOrganizationsPage from "../page/AseclaAdminOrganizationsPage";
import AseclaAdminOrdersPage from "../page/AseclaAdminOrdersPage";
import BonusCodesPage from "../page/BonusCodesPage";
import LicensesListPage from "../page/LicensesListPage";
import HomePage from "../page/Home";
import { useOrganizations } from "../hooks/useOrganizations";
import AseclaOfferPage from "../page/AseclaOfferPage";
import { useLocation } from "react-router-dom";
import AseclaAdminLicensesPage from "../page/AseclaAdminLicensesPage";

export interface HeaderIntegration {
    setContext(context : ContextProps): void;
    setHeader(): void;
    checkLanguage(): void;
}
export const headerIntegration = (): HeaderIntegration => {
    const {t, i18n} = useTranslation();
    let props : ContextProps;
    const {organizations, isAseclaAdmin} = useOrganizations();
    const location = useLocation();

    const setContext = (context : ContextProps) => {
        props = context;
    }

    const disableHeader = (): boolean => {
        return props.freezeHeaderCounter > 0
    }

    const createMenuItem = (titleKey: string, target: string, dataMenuKey?: string): any => {
        
        let link = new (window as any).ASECLA.Commons.MenuLink(dataMenuKey ?? titleKey.replace(" ", "_")
            , t(titleKey)
            , () => props.openPage(target)
            , disableHeader()
        );
        if (location.pathname === "/" && target === HomePage.CONTEXT_PATH
            || (target !== "" && location.pathname.startsWith("/" + target))) {
            link.setCustomStyling({"border-block-style": "double"});
        }
        return link;
    }

    const buildMenuStructure = (): any[] => {
        let curOrganization : OrganizationData | null = props.currentOrganizationNo === -1 ? null : (organizations??[])[props.currentOrganizationNo];
        let menuItems: any[] = [];
        let licensesMenu = menuItems;

        if (location.pathname !== "/" && location.pathname !== "/" + HomePage.CONTEXT_PATH) {
            licensesMenu.push(createMenuItem('Dashboard', HomePage.CONTEXT_PATH));
            if (props.authentication.isAuthenticated()) {          
                if (curOrganization != null) {
                    if (curOrganization.haveManagerRights) {
                        licensesMenu.push(createMenuItem('Buy', AseclaOfferPage.CONTEXT_PATH + "?tname=silver"));
                        licensesMenu.push(createMenuItem('Orders', LicensesByOrderPage.CONTEXT_PATH, 'UserOrders'));
                    }
                    if (curOrganization.haveAdminRights || curOrganization.haveManagerRights) {
                        licensesMenu.push(createMenuItem("Licenses", LicensesListPage.CONTEXT_PATH));
                        licensesMenu.push(createMenuItem("Licenses details", LicensesDetailsPage.CONTEXT_PATH));
                        licensesMenu.push(createMenuItem('Users', ManageUsersPage.CONTEXT_PATH));
                    }
                }

                if (isAseclaAdmin) {
                    let adminSubMenu = createMenuItem('Asecla Admin', AseclaAdminProductsPage.CONTEXT_PATH);
                    adminSubMenu.setCustomStyling({"background-color": "#09d7c3"});
                    menuItems.push(adminSubMenu);
                    adminSubMenu.submenu = [];
                    adminSubMenu.submenu.push(createMenuItem("License Codes", AseclaAdminLicensesPage.CONTEXT_PATH));
                    adminSubMenu.submenu.push(createMenuItem("Bonus Codes", BonusCodesPage.CONTEXT_PATH));
                    adminSubMenu.submenu.push(createMenuItem('Show Products', AseclaAdminProductsPage.CONTEXT_PATH));
                    adminSubMenu.submenu.push(createMenuItem('Show License Types', AseclaAdminLicTypePage.CONTEXT_PATH));
                    adminSubMenu.submenu.push(createMenuItem('Orders', AseclaAdminOrdersPage.CONTEXT_PATH, 'AdminOrders'));
                    adminSubMenu.submenu.push(createMenuItem('Organizations', AseclaAdminOrganizationsPage.CONTEXT_PATH));
                }
            }
        }
        return menuItems;
    }

    const setHeader = () => {
        let menuStructure = buildMenuStructure();
        if ((window as any).ASECLA && (window as any).ASECLA.Commons) {
            (window as any).ASECLA.Commons.Header.getInstance().mainMenu.set(menuStructure);
        }
    }

    const checkLanguage = () => {
        if ((window as any).ASECLA && (window as any).ASECLA.Commons) {
            let headerLang = (window as any).ASECLA.Commons.Locale.getCurrent().code;
            if (i18n.language !== headerLang) {
                i18n.changeLanguage(headerLang, (err, t) => {
                    if (err) return console.log('something gone wrong while loading', err);
                });
            }
        }
    }

    return {
        setContext,
        setHeader,
        checkLanguage,
    }
}