
import { ContextProps } from "../../type/ContextProps";
import { LicenseCode } from "../../type/LicenseCode";
import { useTranslation } from 'react-i18next';
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { DataSource } from "../utils/bigTable/definition/DataSource";
import { CellProperty, HeaderDefinition } from "../utils/bigTable/definition/HeaderDefinition";
import ConstantValuesBigTableFactory from "../utils/bigTable/ConstantValuesBigTableFactory";
import { useNavigate, useSearchParams } from "react-router-dom";
import './LicenseDetails.css';
import { useClaimLicense } from "../../hooks/mutations/useClaimLicense";
import AseclaButton from "../utils/AseclaButton";
import ManageUsersPage from "../../page/ManageUsers";
import TitleBanner from "../utils/TitleBanner";

const predefineHeaders = (aseclaAdminView: boolean): HeaderDefinition[] => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    let headers: HeaderDefinition[] = [];
    let { claimLicense } = useClaimLicense();

    const defineTableHeader = (props: HeaderDefinition) => {
        headers.push(props);
    }

    const get = (getValue: (code: LicenseCode) => any): (cellProperty: CellProperty, dataSource: DataSource) => any => {
        return (cellProperty: CellProperty, dataSource: DataSource) => getValue(cellProperty.object);
    }
    const formatDate = (d: Date|null): string => {
        if (d === undefined || d === null) return "";
        var months = [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')];
        return d.getDate()  + "-" + months[d.getMonth()] + "-" + d.getFullYear();    
    }
    const getExpirationTime = (code: LicenseCode) => {
        if (code.licenseEnd === null) return "";
        if (code.licenseEnd.getTime() < new Date().getTime()) return t("Expired");
        return Math.ceil(Math.abs(code.licenseEnd.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    }
    const activationStartValue = (cellProperty: CellProperty, dataSource: DataSource) => {
        let code: LicenseCode = cellProperty.object;
        let licStart = code.licenseStart === null ? null : new Date(code.licenseStart);
        if (licStart != null) {
            return formatDate(licStart);
        }
        if (!code.canClaimUntil || code.canClaimUntil > new Date()) {
            if ((dataSource.other as any).currOrganizationId === undefined) {
                return <>It's up to user to activate license</>
            }
            return <AseclaButton action={(e) => claimLicense({
                    licenseCodeId: code.id!,
                    organizationId: (dataSource.other as any).currOrganizationId!,
                })}>{t("Claim now") as string}</AseclaButton>
        }
        return "";
    }

    const actionsColumn = (cellProperty: CellProperty, dataSource: DataSource) => {
        let code: LicenseCode = cellProperty.object;
        let buyAction = () => {
            let noOfUsers = "quantity=" + code.usersNo;
            if (code == undefined || code.licenseType == undefined) {
                navigate("/aseclaOfferPage?" + noOfUsers);
            } else {
                navigate("/aseclaOfferPage?tname=" + code.licenseType.technicalName + "&" + noOfUsers);
            }
        };
        return <AseclaButton action={e => {buyAction(); e.preventDefault();}}>{t("Extend") as string}</AseclaButton>
    }

    const getAvailable = (code: LicenseCode) => {
        return <a onClick={e => navigate("/" + ManageUsersPage.CONTEXT_PATH)}>{code.usersNo}</a>;
    }

    const getOrderId = (code: LicenseCode) => {
        if (!aseclaAdminView) {
            return code.orderId;
        }
        if (code.orderId) {
            return <AseclaButton action={e => { navigate("/aseclaAdminOrdersPage?orderId=" + code.orderId); e.preventDefault();}}>{code.orderId}</AseclaButton>
        }
        return "";
    }

    defineTableHeader({headerKey: "LICENSE_CODE_ID", display: aseclaAdminView, alwaysHide: !aseclaAdminView, displayedHeader: t("Id"), getCellContent: get((code) => code.id)});
    defineTableHeader({headerKey: "ORDER_ID", display: aseclaAdminView, displayedHeader: t("Order Id"), getCellContent: get((code) => getOrderId(code))});
    defineTableHeader({headerKey: "PRODUCT_NAME", display: true, displayedHeader: t("Product"), getCellContent: get((code) => code.licenseType.product?.names["en"])});
    defineTableHeader({headerKey: "LICENSE_NAME", display: true, displayedHeader: t("License"), getCellContent: get((code) => code.licenseType.names["en"])});
    defineTableHeader({headerKey: "ORGANIZATION", display: aseclaAdminView, alwaysHide:!aseclaAdminView, displayedHeader: t("Organization"), getCellContent: get((code) => code.organization?.name)});
    defineTableHeader({headerKey: "ACTIVATION_START", display: true, displayedHeader: t("Activation start"), getCellContent: activationStartValue});
    defineTableHeader({headerKey: "ACTIVATION_END", display: true, displayedHeader: t("Activation end"), getCellContent: get((code) => formatDate(code.licenseEnd))});
    defineTableHeader({headerKey: "EXPIRES_IN", display: true, displayedHeader: t("Expires in [days]"), getCellContent: get(getExpirationTime)});
    defineTableHeader({headerKey: "AVAILABLE", display: true, displayedHeader: t("Available"), getCellContent: get(getAvailable)});
    defineTableHeader({headerKey: "ACTION", display: true, displayedHeader: t("Extend"), getCellContent: actionsColumn});

    return headers;
}


type LicensesDetailsParams = {
    aseclaAdminView: boolean
    allLicenses: LicenseCode[]|undefined
    curOrganizationId?: number
}
function LicensesDetails({aseclaAdminView, allLicenses, curOrganizationId}: LicensesDetailsParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [searchParams, ] = useSearchParams();
    const [allHeaders, ] = React.useState<HeaderDefinition[]>(predefineHeaders(aseclaAdminView));
    const [newLicenses, setNewLicenses] = React.useState<number[] | null>(null);
    const [showHidden, setShowHidden] = React.useState<boolean>(false);
    const [searchPattern, setSearchPattern] = React.useState<string>("");
    const [filteredLicenses, setFilteredLicenses] = React.useState<LicenseCode[]>(allLicenses ?? []);

    const {t} = useTranslation();

    React.useEffect(() => {
        const showNewLicenses = searchParams.get('showNewLicenses');
        if (showNewLicenses != null) {
            setNewLicenses(showNewLicenses.split(",").map(idStr => parseInt(idStr)));
        }
        const search = searchParams.get('search');
        if (search != null) {
            setSearchPattern(search);
            filterLicenses(search);
        }
    }, [props.currentOrganizationNo]);

    let codes: LicenseCode[]|undefined = filteredLicenses == undefined ? undefined :
        filteredLicenses.filter(lc => showHidden || lc.licenseEnd == null || lc.licenseEnd.getTime() >= new Date().getTime());

    let expiredExists: boolean = filteredLicenses != undefined && filteredLicenses.find((licCode, i, all) => 
        licCode.licenseEnd != null && licCode.licenseEnd.getTime() < new Date().getTime()
    ) != undefined;

    const filterLicenses = (filter: string) => {
        let upperFilter = filter.toUpperCase();
        setFilteredLicenses(allLicenses!.filter(lic => 
               (lic.id + "").toUpperCase().indexOf(upperFilter) !== -1
        ));
    }
    React.useEffect(() => {
        filterLicenses(searchPattern);
    }, [curOrganizationId]);


    let createNewTable = (licCodes: LicenseCode[]) => {
        let data: DataSource = {
            getCellClassname: (rowNo, _) => {
                if (newLicenses != null && newLicenses.indexOf(licCodes[rowNo].id) !== -1) {
                    return "newLicenseCode";
                } else {
                    return "";
                }
            },
            other: {
                currOrganizationId: curOrganizationId
            }
        }

        let otherHeaderButtons = expiredExists ? [
            <AseclaButton className="small" action={e => setShowHidden(!showHidden)}>{(showHidden ? t("Expired shown") : t("Expired hidden")) as string}</AseclaButton>
        ] : [];

        return <>
            {aseclaAdminView && <>
                <div style={{textAlign: "right"}}>
                    {t("Search") as string}:
                    <input
                        placeholder={t("Search")}
                        value={searchPattern}
                        onChange={e => {filterLicenses(e.target.value); setSearchPattern(e.target.value);}}
                    />
                </div>
                <br></br>
            </>}
            <ConstantValuesBigTableFactory
                dataSource={data}
                allHeaders={allHeaders}
                objects={licCodes}
                initPageSize={100}
                showPagination={false}
                showPageSize={false}
                otherProps={{otherButtonsRight: otherHeaderButtons}}
            ></ConstantValuesBigTableFactory>
        </>
    }

    return <>
        <TitleBanner title={t("License details")} organization={!aseclaAdminView}></TitleBanner>
        {newLicenses != null && <div>{t("Well done: you just got new licenses! Please see them marked below, they are inactive now. Start them if you want to use them already now.") as string}</div>}
        {codes != undefined && createNewTable(codes)}
    </>
}
export default LicensesDetails


