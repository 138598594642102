import React from "react";
import { useOrganizations } from "../../hooks/useOrganizations";
import { ContextProps } from "../../type/ContextProps";
import { OrganizationData } from "../../type/responses/OrganizationData";
import AseclaDataContext from "../../store/AseclaDataContext";
import { useNavigate } from "react-router-dom";
import LicensesDetailsPage from "../../page/LicensesDetailsPage";
import successImg from '../../assets/illustration/success.png';
import styled from "styled-components";
import AseclaButton from "../utils/AseclaButton";
import { Trans } from "react-i18next";
import { t } from "i18next";

function YouHaveLicenseToActivate() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {organizations} = useOrganizations();
    const navigate = useNavigate();

    let curOrganization : OrganizationData | null = props.currentOrganizationNo === -1 ? null : (organizations??[])[props.currentOrganizationNo];

    let haveLicensesToActivate = false;
    if (curOrganization !== null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights)) {
        for (let i = 0; i < curOrganization.licenses.length; i++) {
            if (curOrganization.licenses[i].licenseStart === null) {
                haveLicensesToActivate = true;
            }
        }
    }

    if (!haveLicensesToActivate) {
        return <></>;
    }
    return <SmallIllustrationParagraph data-testid="youHaveLicenseToClaim">
        <img src={successImg}/>
        <div>
            <Trans i18nKey="youHaveALicenseToActivate"
                components={{licenseDetailsRef: <AseclaButton className="small" action={e => navigate("/" + LicensesDetailsPage.CONTEXT_PATH)}> </AseclaButton>
                            , b: <b></b>}}
                values={{buttonText: t("License Details") as string}}
            ></Trans>
        </div>
    </SmallIllustrationParagraph>
}
export default YouHaveLicenseToActivate;


let SmallIllustrationParagraph = styled.div`
    margin: 50px;
    display: flex;
    gap: 20px; /* space between items */
    img {
        width: 50%;
        max-width: 160px;
        height: auto;
    }

    div {
        flex: 1;
    }

`
