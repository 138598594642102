import './UserOrderTable.css'

import React from "react";
import { UserOrder, getLastInvoice } from "../../type/UserOrder";
import ConstantValuesBigTableFactory from "../utils/bigTable/ConstantValuesBigTableFactory";
import {  HeaderDefinition } from '../utils/bigTable/definition/HeaderDefinition';
import { DataSource } from '../utils/bigTable/definition/DataSource';
import OrderDetails from './OrderDetails';
import InoviceData from './OrdersInvoiceData';
import { t } from 'i18next';
import { DATE_OF_PAYMENT, DOWNLOAD_INVOICE, INVOICING_AND_PAYMENT, LICENSE_NAME, ORDER_ID, ORDER_TIME, PRODUCT_NAME, RowData, SHOW_BASKET, STATUS, TOTAL, UPLOAD_DOCUMENT, UserOrderTableColumns } from './UserOrderTableColumns';

import i18n from '../../i18n';
import TitleBanner from '../utils/TitleBanner';

interface PageParameters {
    orderId?: number
}
type UserOrderTableParams = {
    aseclaAdminModule: boolean,
    orders: UserOrder[],
    refresh?: () => void
}
function UserOrderTable({orders, aseclaAdminModule, refresh} : UserOrderTableParams) {
    const [rowData, setRowData] = React.useState<RowData[]>([]);
    const [orderToShowDetails, setOrderToShowDetails] = React.useState<UserOrder|null>(null);
    const [orderNoToShowDetails, setOrderNoToShowDetails] = React.useState<number>(-1);
    const [orderToConfirmInvoiceData, setOrderToConfirmInvoiceData] = React.useState<UserOrder|null>(null);
    const [searchPattern, setSearchPattern] = React.useState<string>("");
    const [filteredOrders, setFilteredOrders] = React.useState<UserOrder[]>(orders);

    const [pageURLParams, setPageURLParams] = React.useState<PageParameters>(() => {
        const params = new URLSearchParams(window.location.search);
        let paramOrderId = params.get('orderId');
        return {            
            orderId: (paramOrderId === undefined || paramOrderId === null) ? undefined : Number.parseInt(paramOrderId)
        };
    });

    const changeOrderToShow = (orderNo: number, newOrderToShow: UserOrder|null) => {
        const updatedFilters: PageParameters = { ...pageURLParams, orderId: newOrderToShow === null ? undefined : newOrderToShow.orderId };
        setPageURLParams(updatedFilters);
        setOrderToShowDetails(newOrderToShow);
        setOrderNoToShowDetails(orderNo);
    
        const params = new URLSearchParams();
        Object.entries(updatedFilters).forEach(([key, value]) => {
            if (value) params.set(key, value.toString());
        });
        
        window.history.pushState({}, '', `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`);
    };

    let columnsToShowByDefault = aseclaAdminModule
        ? [ORDER_ID, PRODUCT_NAME, LICENSE_NAME, STATUS, TOTAL, INVOICING_AND_PAYMENT, DOWNLOAD_INVOICE, SHOW_BASKET]
        : [ORDER_ID, PRODUCT_NAME, LICENSE_NAME, ORDER_TIME, STATUS, DATE_OF_PAYMENT, DOWNLOAD_INVOICE, UPLOAD_DOCUMENT, SHOW_BASKET];
    const [allHeaders, ] = React.useState<HeaderDefinition[]>(UserOrderTableColumns(aseclaAdminModule, setOrderToConfirmInvoiceData, changeOrderToShow, columnsToShowByDefault));
    let tableActions: any = {};

    React.useEffect(() => {
        if (pageURLParams.orderId) {
            let i = getOrderNoByParam(pageURLParams.orderId + "");
            let order = i === -1 ? null : orders[i];
            setOrderNoToShowDetails(i);
            setOrderToShowDetails(order);
        }
        setRowData(filteredOrders.map(order => {
            let res: RowData = {};
            let invoice = getLastInvoice(order);
            if (invoice) {
                res.dateOfPayment = invoice.dateOfPayment == undefined ? undefined : {date: invoice.dateOfPayment, value: invoice.dateOfPayment};
            }
            return res;
        }));
    }, [orders, filteredOrders]);

    // Listen for popstate event (browser back/forward)
    React.useEffect(() => {
        const handlePopState = () => {
            const params = new URLSearchParams(window.location.search);
            let paramOrderId = params.get('orderId');
            let i = getOrderNoByParam(paramOrderId);
            setOrderNoToShowDetails(i);
            setOrderToShowDetails(i === -1 ? null : orders[i]);
            setPageURLParams({
                orderId: i === -1 ? undefined : orders[i].orderId
            });
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, []);

    const getOrderNoByParam = (paramOrderId: string | null): number => {
        let paramId = (paramOrderId === undefined || paramOrderId === null) ? undefined : Number.parseInt(paramOrderId);
        if (paramId !== undefined) {
            for (var i = 0; i < orders.length; i++) {
                if (orders[i].orderId === paramId) {
                    return i;
                }
            }
        }
        return -1;
    }

    let compareToSort = (order1 : UserOrder, order2: UserOrder): number => {
        // if (order1.orderTime == null && order2.orderTime == null) {
            return order1.orderId - order1.orderId;
        // } else if (order1.orderTime == null) {
        //     return -1;
        // } else if (order2.orderTime == null) {
        //     return 1;
        // } else {
        //     return (order1.orderTime! < order2.orderTime!) ? 1 : -1;
        // }
    }

    const filterOrder = (filter: string) => {
        let upperFilter = filter.toUpperCase();
        setFilteredOrders(orders.filter(order => 
               (order.orderId + "").toUpperCase().indexOf(upperFilter) !== -1
            || order.items.filter(item => (
                   item.licenseType.names[i18n.language].toUpperCase().indexOf(upperFilter) !== -1
                || item.licenseType.product.names[i18n.language].toUpperCase().indexOf(upperFilter) !== -1
               )).length > 0
        ).sort(compareToSort));
    }


    let data: DataSource = {
        rowData: rowData,
        setRowData: (rowNo: number, changedRowData: any) => {
            let newRowData = [...rowData];
            newRowData[rowNo] = changedRowData;
            setRowData(newRowData);
        },
    }

    if (orderToConfirmInvoiceData != null) {
        return <InoviceData cancelUserOrder={() => {if (refresh) {refresh()}; setOrderToConfirmInvoiceData(null)}} userOrder={orderToConfirmInvoiceData}></InoviceData>
    }
    if (orderToShowDetails != null) {
        return <OrderDetails
            setOrderToConfirmInvoiceData={setOrderToConfirmInvoiceData}
            changeOrderToShow={changeOrderToShow}
            userOrder={orderToShowDetails}
            rowData={data.rowData![orderNoToShowDetails]}
            modifyRowData={rowData => data.setRowData!(orderNoToShowDetails, rowData)}
            aseclaAdminModule={aseclaAdminModule}
            tableHeaders={allHeaders}></OrderDetails>
    }

    return <div className="no-break">
        <TitleBanner title={t("Orders")} organization={!aseclaAdminModule}></TitleBanner>
        <br/>
        <div style={{textAlign: "right"}}>
            {t("Search") as string}:
            <input
                placeholder={t("Search")}
                value={searchPattern}
                onChange={e => {filterOrder(e.target.value); setSearchPattern(e.target.value); tableActions.resetPagination();}}
            />
        </div>
        <br></br>
        <ConstantValuesBigTableFactory
            dataSource={data}
            allHeaders={allHeaders}
            objects={filteredOrders}
            initPageSize={10}
            actions={tableActions}
        ></ConstantValuesBigTableFactory>
    </div>
}
export default UserOrderTable;