import "./PageStructure.css";

import OrganizationSwitch from "./header/OrganizationSwitch";
import getAuthentication from "../store/AuthenticationPageBase";
import styled from "styled-components";

interface PageStructureParams {
    children?: any,
    showOrganizationSwitch?: boolean
}
function PageStructure({children, showOrganizationSwitch = true}: PageStructureParams) {
    const authentication = getAuthentication();

    let content = <></>;
    if (children != undefined) {
        content = children;
    }

    return <>
        {showOrganizationSwitch && <BottomMarginDiv className="header-content">
            <div className="header-right">
                {authentication.initialized() && authentication.isAuthenticated() && <OrganizationSwitch></OrganizationSwitch>}
            </div>
        </BottomMarginDiv>}
        <main>
            <div id="pageMainContent">
                {content}
            </div>
        </main>
    </>
}

export default PageStructure;

let BottomMarginDiv = styled.div`
    margin-bottom: 16px;
`