import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { requestServerPOST } from "../../store/ServerConnection";
import GenerateOrderLinkRequest from "../../type/request/GenerateOrderLinkRequest";
import OrderLinkResponse from "../../type/responses/OrderLinkResponse";
import ConfirmOrderPage from "../../page/ConfirmOrderPage";
import { t } from "i18next";

interface useGenerateOrderLinkParams {
    licenseTypeId: number,
    noOfUsers: number,
    length: number,
    lengthUnit: string,
    bonusCodeId: number,
    hideBonusCode: boolean,
}
export function useGenerateOrderLink(setOrderLink: (orderLink: string) => void) {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    
    const { mutate: generateOrderLink } = useMutation({
        mutationFn: async ({licenseTypeId, noOfUsers, length, lengthUnit, bonusCodeId, hideBonusCode}: useGenerateOrderLinkParams) => {
            if (!props.authentication.initialized() || !props.authentication.isAuthenticated()) {
                return new Promise(() => null);
            }
            let body: GenerateOrderLinkRequest = {licenseTypeId, noOfUsers, length, lengthUnit, bonusCodeId, hideBonusCode};
            return requestServerPOST("generateOrderLink", body, props);
        },
        onSuccess: (resp: OrderLinkResponse|null) => {
            if (resp != null) {
                let link = location.protocol + '//' + location.host + "/" + ConfirmOrderPage.CONTEXT_PATH + "?order=" + resp.orderId;
                setOrderLink(link);
            }
        },
        onError: (error: any, variables: useGenerateOrderLinkParams) => {
            console.log(error);
            console.log("Can't load order");
            console.log(variables);
            serverError(t("Error while loading order"))
        }
    });
  
    return { generateOrderLink };
}
