import { t } from "i18next";
import styled from "styled-components";
import { LicenseType } from "../../../type/LicenseType";
import Price, { PriceLayout } from "../pricing/Price";
import { Trans } from "react-i18next";
import { BonusCode } from "../../../type/BonusCode";
import React from "react";
import { GetPriceResponse } from "../../../type/responses/GetPriceResponse";
import { DiscountSource } from "../../../type/DiscountSource";
import AseclaButton from "../../utils/AseclaButton";
import i18n from "../../../i18n";

type AseclaProductsColumnParams = {
    columnName: string,
    stepNo: number,
    licType: LicenseType,
    bonusCode?: BonusCode,
    bonusCodeValid?: boolean,
    goStepBack?: () => void,
}
function AseclaProductsColumn({columnName, stepNo, licType, bonusCode, bonusCodeValid, goStepBack}: AseclaProductsColumnParams) {
    const [ priceResponse, setPriceResponse] = React.useState<GetPriceResponse | null>(null);

    let costRow = <></>;
    let discountRow = <></>;
    if (stepNo === 1 || stepNo === 2) {
        costRow = <div className="wordField lightWordField halfSizeCaption">
                        {<Trans i18nKey="PriceColumnformatting" 
                            components={{
                                BigSpan: <BigSpan></BigSpan>,
                                price: <Price length={1} licenseType={licType!} quantity={1}
                                    bonusCode={bonusCode} bonusCodeValid={bonusCodeValid} showDiscount={true}
                                    showHint={false} setPrice={setPriceResponse} priceLayout={PriceLayout.DIV_PURE_PRICE}></Price>
                                // price: <PriceCaption price={licType.price} currency="zł" priceToCurrencySpace={false}></PriceCaption>
                            }}
                            values={{
                                priceDescription: t("Price per license per unit description", {unit: t(licType.lengthUnit).toLowerCase()})
                            }}
                        ></Trans>}
                    </div>;
    }

    if ( priceResponse !== null) {
        if (priceResponse.discountSource === DiscountSource.Organization) {
            discountRow = <div className="wordField lightWordField crowdedWordField">{t("The discount is based on your Organization Discount", {discount: priceResponse.percentageDiscountValue + "%"}) as string}</div>
        }
        if (priceResponse.discountSource === DiscountSource.BonusCode) {
            let discount = "";
            if (priceResponse.discountType === "Percentage") {
                discount = priceResponse.percentageDiscountValue + "%";
            } else {
                discount = priceResponse.discountedValue + priceResponse.currency;
            }
            discountRow = <div  className="wordField lightWordField crowdedWordField">{t("The discount comes from your bonuscode", {discount: discount}) as string}</div>
        }
    }

    let columnClass = () => {
        switch (columnName) {
            case "Hello": return "column column1";
            case "Silver": return "column column2";
            case "Gold": return "column column3";
            case "Platinum": return "column column4";
        }
        return "";
    }

    let lang = i18n.language;

    return <>
        {stepNo === 2 && goStepBack !== undefined && <><AseclaButton className="small" action={e => {goStepBack!()}}>{t("<< Zmień zamówienie") as string}</AseclaButton></>}
        <PaddingDiv className={"product_pricing_table " + lang}>
            <DataColumn columnClass={columnClass()} licType={licType} costRow={costRow} discountRow={discountRow}></DataColumn>
        </PaddingDiv>
    </>
}
export default AseclaProductsColumn;

const DataColumn = ({columnClass, licType, costRow, discountRow}: {columnClass: string, licType: LicenseType, costRow: any, discountRow: any}) => {
    const getUserFriendlyLimit = (limitKey: string) => {
        let propId = licType.product.properties.filter(prop => prop.name === limitKey)[0].propertyId;
        let limitNumber = Number.parseInt(licType.properties[propId]);
        if (Number.isNaN(limitNumber)) {
            return "Undefined";
        }
        if (limitNumber < 1024) {
            return limitNumber + " MB";
        }
        limitNumber = Math.round(limitNumber / 1024);
        if (limitNumber < 1024) {
            return limitNumber + " GB";
        }
        limitNumber = Math.round(limitNumber / 1024);
        return limitNumber + " TB";
    }

    return <ProductColumn className={columnClass}>
            
            <div className="wordField lightWordField bitCrowdedField">
                {licType.maxQuantity === 0 && (t("Unlimited max number of users") as string)}
                {licType.maxQuantity > 0 && (t("Ilość Użytkowników: do", {users: licType.maxQuantity}) as string)}
            </div>
            <div className="wordField lightWordField bitCrowdedField">{t("Size of message including attachments ___", {limit: getUserFriendlyLimit("TotalSizeOfAttachmentsMB")}) as string}</div>
            <div className="wordField lightWordField bitCrowdedField">{t("Mięsięcznie do ", {limit: getUserFriendlyLimit("MaxMonthlyUserTotalSizeMB")}) as string}</div>
            <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
            <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
            <div className="wordField lightWordField bitCrowdedField">{t("Możliwość dodania hasła: Tak") as string}</div>
            <div className="wordField lightWordField bitCrowdedField">{t("Dezaktywacja linku: Tak") as string}</div>
            <div className="wordField lightWordField bitCrowdedField">{t("Wsparcie telefoniczne: Nie") as string}</div>
            <div className="wordField lightWordField bitCrowdedField">{t("Priorytetowe wsparcie email: Tak") as string}</div>
            {costRow}
            {discountRow}
        </ProductColumn>
}

const ProductColumn = styled.div`
    div.baseValue {
        line-height: 10px;
        font-weight: 100;
        font-size: 0.7em;
    }
    &.product_pricing_table.pl, div.wordField.lightWordField.bitCrowdedField {
        font-size: clamp(16px, 3vw, 16px);
    }
    &.product_pricing_table.pl, div.wordField.lightWordField.crowdedWordField {
        font-size: clamp(12px, 2.5vw, 18px);
    }
    &.product_pricing_table, div.wordField.lightWordField.halfSizeCaption {
        font-size: clamp(12px, 2.5vw, 14px);
    }
`;

const BigSpan = styled.div`
    font-weight: 700;
    font-size: 1.2em;
    vertical-align: baseline;
    position: relative;
    top: -0.1em;
    white-space: nowrap;

    span.finalValue {
        font-size: 1.2em;
    }
`;

const PaddingDiv = styled.div`
    div.column.column2,
    div.column.column3,
    div.column.column4 {
        margin-left: 0px;
        margin-right: 0px;
    }

    div.column div.wordField {
        font-weight: 200;
        height: 50px;
    }

    div.column div.wordField.crowdedWordField {
        height: 70px;
    }
        
    div.column div.bitCrowdedField {
        line-height: 20px;
    }

    div.column div.bitCrowdedField {
        @media (max-width: 768px) {
            font-size: clamp(16px, 3vw, 20px);
        }
    }

`;
