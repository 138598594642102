import LicensesDetails from "./LicensesDetails"
import { useLicenseCodes } from "../../hooks/queries/useLicenseCodes";
import Loading from "../utils/Loading";

function LicenseDetailsASECLAAdminView() {
    const {licenseCodes, isLoading, isError} = useLicenseCodes();

    if (isError) {
        return <div>Couldn't load licenses</div>;
    }

    if (isLoading) {
        return <Loading></Loading>
    }
        
    return <LicensesDetails aseclaAdminView={true} allLicenses={licenseCodes}></LicensesDetails>
}
export default LicenseDetailsASECLAAdminView
